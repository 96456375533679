import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { HotkeyService } from './hotkey.service';
import { KeyboardKey } from './keyboard.service';
import { DeviceInfoService } from '@compass/environment';
import { TaskQueue } from '@compass/helpers';

@Injectable({
  providedIn: 'root',
})
export class CalculatorService implements OnDestroy {
  private _enabled: boolean = false;
  private _hotkeySubId?: number;
  private _visible: boolean = false;
  private _isFullscreen: boolean = false;

  populateTypeText: Subject<string> = new Subject<string>();

  constructor(
    private readonly _hotkeyService: HotkeyService,
    private readonly deviceInfo: DeviceInfoService,
  ) {}

  ngOnDestroy(): void {
    this.removeCalculatorHotkey();
  }

  get enabled(): boolean {
    return this._enabled;
  }

  set enabled(value: boolean) {
    TaskQueue.scheduleOnMicroQueue(() => {
      this._enabled = value;

      if (value) {
        this.addCalculatorHotkey();
      } else {
        this.removeCalculatorHotkey();
      }
    });
  }

  get visible(): boolean {
    return this.enabled && this._visible;
  }

  public get isFullScreen(): boolean {
    return this._isFullscreen;
  }

  set isFullScreen(value: boolean) {
    this._isFullscreen = this.deviceInfo.device.isMobile ? value : true;
  }

  show(): void {
    if (!this.enabled) return;

    this._visible = true;
  }

  hide(): void {
    this._visible = false;
  }

  toggle(): void {
    if (this.visible) {
      this.hide();
    } else {
      this.show();
    }
  }

  setNewResult(newResult: string | number): void {
    this.populateTypeText.next(newResult.toString());
  }

  private addCalculatorHotkey(): void {
    if (this._hotkeySubId) return;

    this._hotkeySubId = this._hotkeyService.add(
      KeyboardKey.KeyC,
      this.toggle.bind(this),
      {
        requireAlt: true,
        requireShift: false,
        requireCtrl: false,
      },
    );
  }

  private removeCalculatorHotkey(): void {
    if (!this._hotkeySubId) return;

    this._hotkeyService.remove(this._hotkeySubId);
  }
}
