import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DebugProviderService } from '@compass/environment';
import { TimerService } from '../../../services/assessment/timer.service';
import {
  AssessmentState,
  AssessmentStateService,
} from '../../../services/assessment/assessment-state.service';
import { PresentationService } from '../../../services/assessment/presentation.service';

const DEBUG_STORAGE_KEY = 'timer-enabled';

@Component({
  selector: 'cp-par-debug-timer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './debug-timer.component.html',
})
export class DebugTimerComponent {
  constructor(
    protected readonly timer: TimerService,
    protected readonly presentation: PresentationService,
    private readonly _debug: DebugProviderService,
    private readonly _state: AssessmentStateService,
  ) {
    const timerEnabled = this._debug.getData<boolean>(DEBUG_STORAGE_KEY);

    if (timerEnabled === undefined) return;

    this.timer.enabled = timerEnabled;
  }

  protected toggleTimer(): void {
    this.timer.enabled = !this.timer.enabled;

    if (
      this.timer.enabled &&
      this._state.currentState === AssessmentState.InProgress
    ) {
      this.timer.timerForCurrentGroup?.start();
    }

    this._debug.storeData(DEBUG_STORAGE_KEY, this.timer.enabled);
  }
}
