<div class="position-relative h-100 w-100 d-flex">
  @if (!disabled) {
    <cp-fa-icon
      class="p-3 rounded-1 border border-primary text-white expansion"
      (click)="toggleFunc()"
      size="medium"
      iconStyle="solid"
      [icon]="
        isExpanded
          ? 'down-left-and-up-right-to-center'
          : 'up-right-and-down-left-from-center'
      " />
  }
  <ng-content></ng-content>
</div>
