import {
  inject,
  ModuleWithProviders,
  NgModule,
  ProviderToken,
} from '@angular/core';
import { Requester } from './services/requester';
import { BASE_API_URL } from './helpers';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  JWT_TOKEN_PROVIDER,
  JwtInterceptor,
} from './interceptors/jwt.interceptor';
import { JwtProvider } from './interceptors/jwt-provider';

@NgModule({
  imports: [],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class CompassHttpModule {
  public static forRoot(
    baseApiUrl: string,
    tokenProvider?: ProviderToken<JwtProvider> | undefined,
  ): ModuleWithProviders<CompassHttpModule> {
    return {
      ngModule: CompassHttpModule,
      providers: [
        { provide: BASE_API_URL, useValue: baseApiUrl },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        {
          provide: JWT_TOKEN_PROVIDER,
          useFactory: () => {
            // If no provider, then return a function that returns undefined.
            if (!tokenProvider) {
              return () => undefined;
            }

            const instance = inject(tokenProvider);

            return instance.getToken.bind(instance);
          },
        },
        Requester,
      ],
    };
  }
}
