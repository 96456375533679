import { Injectable, OnDestroy } from '@angular/core';
import { Timer } from '../../models/content-group/timer';
import { ReplaySubject, Subject } from 'rxjs';
import { ContentGroupTimer } from '../../models/assessment/content-group-timer';
import { AssessmentContentService } from './assessment-content.service';
import { TimerState } from '../../models/assessment/timer-state';
import { ContentClient } from '../../clients/content.client';
import { Logger } from '@compass/logging';
import {
  ContentGroupChangeArgs,
  PresentationService,
} from './presentation.service';

@Injectable({
  providedIn: 'root',
})
export class TimerService implements OnDestroy {
  private readonly _timers = new Map<string, Timer>();
  private readonly _timerExpired = new ReplaySubject<ContentGroupTimer>(1);
  private readonly _timerStarted = new Subject<ContentGroupTimer>();
  private readonly _timerStopped = new Subject<ContentGroupTimer>();
  private readonly _groupChangeSub =
    this._presentation.contentGroupChange$.subscribe(
      this.onContentGroupChange.bind(this),
    );

  private _enabled = true;
  private _timerForCurrentGroup?: Timer;

  readonly timerExpired$ = this._timerExpired.asObservable();
  readonly timerStarted$ = this._timerStarted.asObservable();
  readonly timerStopped$ = this._timerStopped.asObservable();

  get enabled(): boolean {
    return this._enabled;
  }

  set enabled(value: boolean) {
    this._enabled = value;

    for (const timer of this._timers.values()) {
      timer.enabled = value;
    }
  }

  get timerForCurrentGroup(): Timer | undefined {
    return this._timerForCurrentGroup;
  }

  constructor(
    contentClient: ContentClient,
    private readonly _logger: Logger,
    private readonly _content: AssessmentContentService,
    private readonly _presentation: PresentationService,
  ) {
    this.init(contentClient.data.progress?.timerStates);

    this._timerForCurrentGroup = this.getTimerForGroup(
      this._presentation.current.contentGroup.sourceId,
    );
  }

  ngOnDestroy(): void {
    this._groupChangeSub.unsubscribe();
  }

  /**
   * Retrieves a {@link Timer} object by its source ID.
   *
   * @param {string} timerSourceId - The source ID of the Timer to retrieve.
   * @return {Timer | undefined} The Timer object corresponding to the source ID, or undefined if not found.
   */
  getTimer(timerSourceId: string): Timer | undefined {
    return this._timers.get(timerSourceId);
  }

  /**
   * Retrieves the timer associated with the specified group source ID.
   *
   * @param {string} groupSourceId - The source ID of the group.
   * @return {Timer | undefined} - The timer object associated with the group source ID, or undefined if the group or timer is not found.
   */
  getTimerForGroup(groupSourceId: string): Timer | undefined {
    const group = this._content.contentGroups.find(
      cg => cg.sourceId === groupSourceId,
    );

    if (!group?.timer) return undefined;

    return this.getTimer(group.timer.sourceId);
  }

  /**
   * Starts the timer for a content group.
   *
   * @param {string} contentGroupSourceId - The source ID of the content group.
   * @returns {void}
   */
  startTimer(contentGroupSourceId: string): void {
    if (!this.enabled) return;

    const contentGroup = this._content.contentGroups.find(
      cg => cg.sourceId === contentGroupSourceId,
    );

    // Do not start the timer if
    // - the content group is not found
    // - the content group is not timed
    // - the timer is paused for group
    if (!contentGroup?.timer) return;

    if (contentGroup.isTimerPaused) {
      this._logger.debug(
        `Refused to start timer for content group [${contentGroupSourceId}] because it either does not define a timer or the timer is paused.`,
      );
      return;
    }

    this._timers.get(contentGroup.timer.sourceId)?.start();
  }

  /**
   * Stops the timer for the specified content group.
   *
   * @param {string} contentGroupSourceId - The source ID of the content group.
   * @returns {void}
   */
  stopTimer(contentGroupSourceId: string): void {
    const contentGroup = this._content.contentGroups.find(
      cg => cg.sourceId === contentGroupSourceId,
    );

    if (!contentGroup?.timer) return;

    this._timers.get(contentGroup.timer.sourceId)?.stop();
  }

  /**
   * Stops all timers.
   *
   * @returns {void}
   */
  stopAllTimers(): void {
    for (const timer of this._timers.values()) {
      timer.stop();
    }
  }

  private onContentGroupChange(change: ContentGroupChangeArgs): void {
    this._timerForCurrentGroup = this.getTimerForGroup(
      change.newGroup.sourceId,
    );
  }

  private init(startStates?: TimerState[]): void {
    for (const contentGroup of this._content.contentGroups) {
      const groupTimer = contentGroup.timer;

      if (!groupTimer || this._timers.has(groupTimer.sourceId)) continue;

      const progress = startStates?.find(
        t => t.timerSourceId === groupTimer.sourceId,
      );
      const timer = new Timer(
        groupTimer.sourceId,
        groupTimer.durationSeconds,
        progress?.secondsElapsed,
      );

      // Subscribe to the events
      timer.expired$.subscribe(() => this._timerExpired.next(groupTimer));
      timer.started$.subscribe(() => this._timerStarted.next(groupTimer));
      timer.stopped$.subscribe(() => this._timerStopped.next(groupTimer));

      this._timers.set(groupTimer.sourceId, timer);
    }
  }
}
