import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HotkeyDirective } from '../../directives/hotkey.directive';
import { KeyboardKey } from '../../services/ui/keyboard.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { TimerService } from '../../services/assessment/timer.service';
import {
  AssessmentState,
  AssessmentStateService,
} from '../../services/assessment/assessment-state.service';
import { PresentationService } from '../../services/assessment/presentation.service';

@Component({
  selector: 'cp-par-expired-button',
  standalone: true,
  imports: [CommonModule, HotkeyDirective],
  templateUrl: './expired-button.component.html',
})
export class ExpiredButtonComponent {
  private readonly _timerService = inject(TimerService);
  private readonly _presentation = inject(PresentationService);
  private readonly _state = inject(AssessmentStateService);

  protected readonly KeyboardKey = KeyboardKey;

  protected readonly buttonText = toSignal(this._timerService.timerExpired$);

  protected goNext(): void {
    this._state.setState(
      this._presentation.current.contentGroup.isCompleteGroup
        ? AssessmentState.Complete
        : AssessmentState.InProgress,
    );
  }
}
