<ng-container
  *ngIf="initialized"
  cpParSplitScreen
  [enabled]="calculatorService.visible && canSplitScreen"
  [availableHeightCallback]="setContentHeightFn"
  (isLandscape)="isLandscape = $event"
  class="d-flex flex-column">
  <header #header class="bg-dark text-white d-flex align-items-center py-2">
    <cp-fa-icon
      iconStyle="thin"
      size="xlarge"
      class="ms-2 cursor-pointer position-absolute"
      ngbTooltip="Hide/Show debug window"
      *ngIf="
        deviceInfo.device.isDesktop &&
        debug.enabled &&
        environment.isDevelopment
      "
      (click)="toggleDebugWindow()">
      gear-code
    </cp-fa-icon>

    <cp-par-header class="container px-md-5" />
  </header>

  <main
    cpParSplitScreenItem
    [ngClass]="{
      'd-none': calculatorService.isFullScreen && calculatorService.visible,
    }">
    <div class="container shadow-sm bg-white py-2 px-md-5 overflow-auto">
      <router-outlet />
    </div>
  </main>

  <cp-par-expandable
    cpParSplitScreenItem
    [fullScreen]="
      calculatorService.isFullScreen ||
      (deviceInfo.device.isDesktop &&
        canSplitScreen &&
        window.innerHeight < 650)
    "
    [allowFullscreen]="canSplitScreen"
    [defaultOnLandscape]="true"
    [isExpanded]="calculatorService.isFullScreen"
    (expansionChanged)="calculatorFullscreenChange($event)"
    [disabled]="
      isLandscape ||
      !calculatorService.visible ||
      !deviceInfo.device.isMobile ||
      deviceInfo.device.isTablet
    ">
    <div class="flex-1 w-100">
      <cp-par-calculator
        #calculator
        class="calculator"
        *ngIf="calculatorService.enabled"
        [canDrag]="!deviceInfo.device.isMobile && window.innerWidth > 991" />
    </div>
  </cp-par-expandable>

  <footer #footer class="bg-dark py-2">
    <div class="container px-md-5">
      <cp-par-desktop-footer *ngIf="deviceInfo.device.isDesktop" />
      <cp-par-mobile-footer *ngIf="!deviceInfo.device.isDesktop" />
    </div>
  </footer>
</ng-container>

<cp-spinner [fullscreen]="true" size="xxlarge" *ngIf="!initialized">
  Please wait
</cp-spinner>
