import { Injectable, signal } from '@angular/core';
import { PresentationService } from '../assessment/presentation.service';
import { ProgressService } from '../assessment/progress.service';
import {
  NavigationLock,
  NavigationService,
} from '~/shared/services/assessment/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  public readonly forwardNavigationDisabled = signal(false);

  constructor(
    private readonly _presentationService: PresentationService,
    private readonly _progressService: ProgressService,
    private readonly _navigationService: NavigationService,
  ) {}

  public async videoCompleted(moveNext: boolean = false): Promise<void> {
    this.setState(false, false, false);

    if (moveNext) {
      await this._navigationService.goNext();
    }
  }

  public videoLoaded(): void {
    this._progressService.currentItemTracker.addView(); // updates view count
    this._progressService.updateProgress();
  }

  public init(
    disableForwardNavigation: boolean,
    disablePreviousNavigation: boolean,
    hideChoices: boolean,
  ): void {
    this.setState(
      disableForwardNavigation,
      disablePreviousNavigation,
      hideChoices,
    );
  }

  private setState(
    disableForwardNavigation: boolean,
    disablePreviousNavigation: boolean,
    hideChoices: boolean,
  ): void {
    this._presentationService.hideChoices.set(hideChoices);

    if (disableForwardNavigation && disablePreviousNavigation) {
      this._navigationService.lock();
    } else if (disableForwardNavigation && !disablePreviousNavigation) {
      this._navigationService.lock(NavigationLock.Next);
    } else if (disablePreviousNavigation && !disableForwardNavigation) {
      this._navigationService.lock(NavigationLock.Back);
    } else {
      this._navigationService.unlock();
    }

    this.forwardNavigationDisabled.set(disableForwardNavigation);
  }
}
