import {
  AfterViewInit,
  Component,
  ComponentRef,
  Input,
  Optional,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogOptions } from '../../types/dialog-options';
import {
  defaultDialogConfiguration,
  DialogConfig,
} from '../../types/dialog-config';
import { ModalHeaderComponent } from '../modal-header/modal-header.component';
import { DialogStyle } from '../../types/dialog-style';
import { Logger } from '@compass/logging';
import { SafeHtmlPipe } from '@compass/pipes';
import { Dialog } from 'primeng/dialog';

@Component({
  selector: 'cp-dialog-outlet',
  templateUrl: './dialog-outlet.component.html',
  styleUrls: ['./dialog-outlet.component.scss'],
  standalone: true,
  imports: [CommonModule, ModalHeaderComponent, SafeHtmlPipe, Dialog],
})
export class DialogOutletComponent implements AfterViewInit {
  private _onDialogResolve?: (result: boolean) => void;

  @Input({ required: true })
  displayOptions!: DialogOptions;

  @Input({ required: true })
  instance!: ComponentRef<DialogOutletComponent>;

  @Input()
  dialogStyle: DialogStyle = 'normal';

  @Input()
  options: DialogConfig = defaultDialogConfiguration;

  @ViewChild('dialogInstance', { static: false })
  dialogInstance!: Dialog;

  protected confirmPending: boolean = false;
  protected visible: boolean = true;

  get showHeader(): boolean {
    return !!this.displayOptions.title || this.options.showCloseButton;
  }

  get showFooter(): boolean {
    return this.options.showConfirm || this.options.showCancel;
  }

  constructor(
    private readonly renderer: Renderer2,
    @Optional()
    private readonly _logger?: Logger,
  ) {}

  ngAfterViewInit(): void {
    // removes the Primeng header class that adds unwanted padding to dialog.
    // Setting p-dialog's showHeader parameter to false has no effect and the header
    // CSS class must be manually removed.
    if (this.dialogInstance?.headerViewChild && !this.showHeader) {
      this.renderer.removeClass(
        this.dialogInstance.headerViewChild.nativeElement,
        'p-dialog-header',
      );
    }

    if (this.dialogInstance && this.options.fullscreen)
      this.dialogInstance.maximize();
  }

  onDialogResolve(callback: (result: boolean) => void): void {
    this._onDialogResolve = callback;
  }

  destroy(): void {
    this.destroyDialog();
  }

  cancel(): void {
    this.destroyDialog(false);
  }

  async confirm(): Promise<void> {
    if (!(await this.runBeforeConfirmCallback())) {
      this._logger?.debug(
        'Refused to close dialog because close was prevented by callback function.',
      );
      return;
    }

    this.destroyDialog(true);
  }

  private destroyDialog(result?: boolean): void {
    if (!this.instance) {
      throw new Error(
        'The component instance was not provided and the component cannot destroy itself.',
      );
    }

    if (result !== undefined) {
      this._onDialogResolve?.(result);
    }

    this.instance.destroy();
  }

  private async runBeforeConfirmCallback(): Promise<boolean> {
    // If no callback is set then return true
    if (!this.options.beforeConfirm) return true;

    try {
      this.confirmPending = true;

      const callbackResult = this.options.beforeConfirm();

      // If it's synchronous function then return the value
      if (typeof callbackResult === 'boolean') {
        return callbackResult;
      }

      // If it's async then await the result and return it
      return await callbackResult;
    } catch (error) {
      this._logger?.error(
        'An error occurred in a callback before dialog confirmation.',
        error,
      );

      return false;
    } finally {
      this.confirmPending = false;
    }
  }
}
