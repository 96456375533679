@switch (state.currentState) {
  @case (AssessmentState.Support) {
    <cp-par-submit-support-button />
  }
  @case (AssessmentState.Expired) {
    <cp-par-expired-button />
  }
  @case (AssessmentState.Resume) {
    <cp-par-resume-button />
  }
  @default {
    <cp-par-next-button />
  }
}
