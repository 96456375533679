import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[cpParSplitScreenItem]',
  standalone: true,
})
export class SplitScreenItemDirective implements OnChanges {
  /**
   * If element is currently full screen
   */
  @Input() fullScreen: boolean = false;

  /**
   * If fullscreen should be allowed
   */
  @Input() allowFullscreen: boolean = false;

  /**
   * If this element should be the default element when
   * a mobile device enters landscape mode
   */
  @Input() defaultOnLandscape: boolean = false;

  /**
   * Event triggered fullscreen is toggled for the element
   */
  @Output() toggleFullscreen: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  private readonly _id: string = Math.random().toString(16).slice(2);

  get id(): string {
    return this._id;
  }

  constructor(
    private readonly _elementRef: ElementRef,
    private readonly _renderer: Renderer2,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const fullScreen = changes['fullScreen'];

    if (!fullScreen || fullScreen.currentValue === fullScreen.previousValue)
      return;

    this.toggleFullscreen.emit(this.fullScreen);
  }

  public setHeight(height: number): void {
    if (!this._elementRef) return;
    this._renderer.setStyle(
      this._elementRef.nativeElement,
      'height',
      `${height}px`,
    );
  }
}
