import { inject, Injectable } from '@angular/core';
import {
  ContentGroupWithIndex,
  PresentationService,
} from '~/shared/services/assessment/presentation.service';
import { TimerService } from '~/shared/services/assessment/timer.service';
import { ProgressService } from '~/shared/services/assessment/progress.service';
import { SelectionService } from '~/shared/services/assessment/selection.service';
import { IdentityService } from '~/shared/services/ui/identity.service';
import { TermsAndConditionsService } from '~/shared/services/ui/terms-and-conditions.service';
import {
  AssessmentExitReason,
  AssessmentService,
} from '~/shared/services/assessment/assessment.service';
import { ModalService } from '@compass/notifications';
import { PresentationItem } from '~/shared/models/assessment/presentation-item';

export enum NavigationResult {
  Allowed,
  NotFound,
  TimerExpired,
  ReentryNotAllowed,
  NoRemainingViews,
  NavigationLocked,
  InvalidIdentity,
  IncorrectSelection,
  ConfirmTerms,
}

export enum NavigationLock {
  Back,
  Next,
  All,
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private readonly _presentation = inject(PresentationService);
  private readonly _progress = inject(ProgressService);
  private readonly _timer = inject(TimerService);
  private readonly _selection = inject(SelectionService);
  private readonly _assessment = inject(AssessmentService);
  private readonly _termsAndConditions = inject(TermsAndConditionsService);
  private readonly _identity = inject(IdentityService);
  private readonly _modal = inject(ModalService);

  private _lock?: NavigationLock;

  /**
   * Determines if navigating to the next content item is allowed.
   *
   * Checks various conditions such as navigation locks, validity of the user's identity,
   * selection rules, and group-specific navigation rules to decide whether navigation
   * should proceed.
   *
   * @return {NavigationResult} Returns a result indicating the outcome of the navigation check
   */
  canGoNext(): NavigationResult {
    if (
      this._lock === NavigationLock.All ||
      this._lock === NavigationLock.Next
    ) {
      return NavigationResult.NavigationLocked;
    }

    const current = this._presentation.current;
    const target = this._presentation.current.next;

    if (!target.presentationItem) {
      return NavigationResult.NotFound;
    }

    if (!this._identity.isValid()) {
      return NavigationResult.InvalidIdentity;
    }

    const numberOfItemsSelected = this._selection.getForQuestion(
      current.presentationItem.questionId,
    ).length;

    if (
      numberOfItemsSelected < current.presentationItem.minimumResponses ||
      numberOfItemsSelected > current.presentationItem.maximumResponses
    ) {
      return NavigationResult.IncorrectSelection;
    }

    // If we are not changing group, allow the navigation
    if (target.contentGroup?.sourceId === current.contentGroup.sourceId) {
      return NavigationResult.Allowed;
    }

    // Otherwise check the group rules
    let nextGroupEntryStatus = this.canEnterContentGroup(target.contentGroup);

    if (nextGroupEntryStatus === NavigationResult.ReentryNotAllowed) {
      nextGroupEntryStatus = NavigationResult.Allowed;
    }

    return nextGroupEntryStatus;
  }

  /**
   * Determines whether navigation to the previous item is allowed.
   * This method evaluates the navigation lock status, the presence of a previous item,
   * and the state of the current and previous content groups to decide the result.
   *
   * @return {NavigationResult} A `NavigationResult` indicating the outcome of the navigation check
   */
  canGoBack(): NavigationResult {
    if (
      this._lock === NavigationLock.All ||
      this._lock === NavigationLock.Back
    ) {
      return NavigationResult.NavigationLocked;
    }

    if (!this._presentation.current.previous.presentationItem) {
      return NavigationResult.NotFound;
    }

    const current = this._presentation.current;
    const target = this._presentation.current.previous;

    // If there is no previous item then there is nothing to go back to
    if (!target?.presentationItem) {
      return NavigationResult.NotFound;
    }

    // If previous item exhausted views then we cannot go back
    if (!this.hasRemainingViews(target.presentationItem)) {
      return NavigationResult.NoRemainingViews;
    }

    // If are NOT about to change to another
    // content group then allow navigation.
    if (!current.isFirstInGroup) {
      return NavigationResult.Allowed;
    }

    // If we are in complete group we cannot go anywhere
    if (current.contentGroup.isCompleteGroup) {
      return NavigationResult.NavigationLocked;
    }

    // We are about to exit current content group
    // Check that we can enter the target group
    const targetGroupEntry = this.canEnterContentGroup(target.contentGroup);

    // If we cannot enter the target group then return the status right
    // away - no need to check anything else.
    if (targetGroupEntry !== NavigationResult.Allowed) {
      return targetGroupEntry;
    }

    // We can enter the previous content group...
    // Check that when we exit we can reenter the CURRENT group -
    // if we cannot reenter the current group after going back we will
    // not allow to exit it at all
    return this.canEnterContentGroup(current.contentGroup);
  }

  /**
   * Navigates back to the previous presentation item if navigation is allowed.
   * based on certain validation checks and the current state of the presentation.
   *
   * @return {Promise<void>} A Promise resolved when the navigation completes or
   *         if no navigation action is performed.
   */
  async goNext(): Promise<void> {
    const navResult = this.canGoNext();
    if (navResult !== NavigationResult.Allowed) {
      this.showNavigationResult(navResult);
      return;
    }

    // If the user does not accept terms & conditions
    // the do nothing.
    if (!(await this._termsAndConditions.confirmTerms())) {
      this.showNavigationResult(NavigationResult.ConfirmTerms);
      return;
    }

    // If identity needs updating and the update fails, then
    // do not progress anywhere.
    if (!(await this.updateParticipantIdentity())) {
      this.showNavigationResult(NavigationResult.InvalidIdentity);
      return;
    }

    // Check if the selected option defines leaping to another content group
    const leapGroupSourceId = this.getLeapGroupSourceId();

    if (leapGroupSourceId) {
      // If the target group is the same as current group leap to the first index in group
      // Try to unselect option if we can
      this.unselectAllForCurrent();

      if (
        leapGroupSourceId === this._presentation.current.contentGroup.sourceId
      ) {
        return this.goToIndex(this._presentation.current.contentGroup.index);
      } else {
        // Otherwise go to that group
        return this.goToGroup(leapGroupSourceId);
      }
    }

    if (
      this._presentation.current.isLastInGroup &&
      this._presentation.current.next.contentGroup
    ) {
      return this.goToGroup(
        this._presentation.current.next.contentGroup.sourceId,
        false,
        true,
      );
    }

    this.goToIndex(this._presentation.current.next.presentationItem?.index);
  }

  /**
   * Navigates back to the previous presentation item if navigation is allowed.
   *
   * @return {Promise<void>} A promise that resolves when the navigation action is complete.
   */
  async goBack(): Promise<void> {
    if (this.canGoBack() !== NavigationResult.Allowed) {
      return;
    }

    this.goToIndex(this._presentation.current.previous.presentationItem?.index);
  }

  /**
   * Navigates to the specified content group by its source ID. Performs checks
   * to ensure the group exists and can be entered, and handles finalization and
   * navigation as needed.
   *
   * @param {string} groupSourceId - The source ID of the group to navigate to.
   * @param {boolean} [seekAvailable=false] - Indicates whether to seek an available group if the target group is not accessible.
   * @param disableReentryRules - TEMPORARY!!! This is to give us time to fix session concurrency
   * @return {Promise<void>} Resolves with no value once the navigation is completed.
   */
  async goToGroup(
    groupSourceId: string,
    seekAvailable: boolean = false,
    disableReentryRules: boolean = false,
  ): Promise<void> {
    if (seekAvailable) {
      groupSourceId = this.getNextAvailableContentGroup(groupSourceId);
    }

    let groupEntryStatus = this.canEnterContentGroup(groupSourceId);

    // Temporarily suspend reentry rules if requested
    if (
      disableReentryRules &&
      groupEntryStatus === NavigationResult.ReentryNotAllowed
    ) {
      groupEntryStatus = NavigationResult.Allowed;
    }

    if (groupEntryStatus !== NavigationResult.Allowed) {
      this.showNavigationResult(groupEntryStatus);
      return;
    }

    const contentGroup = this._presentation.getContentGroup(groupSourceId)!;

    if (contentGroup.isCompleteGroup) {
      await this._assessment.finalize();
    }

    this.goToIndex(contentGroup.index);
  }

  lock(lockType: NavigationLock = NavigationLock.All): void {
    this._lock = lockType;
  }

  unlock(): void {
    this._lock = undefined;
  }

  private getNextAvailableContentGroup(contentGroupSourceId: string): string {
    const currentGroup =
      this._presentation.getContentGroup(contentGroupSourceId);

    // If the given group is not found then start searching from the current one
    if (!currentGroup) {
      return this.getNextAvailableContentGroup(
        this._presentation.current.contentGroup.sourceId,
      );
    }

    // If we can enter the group then return it
    if (
      this.canEnterContentGroup(currentGroup.sourceId) ===
      NavigationResult.Allowed
    ) {
      return currentGroup.sourceId;
    }

    // If we cannot enter the group AND there is not a next one,
    // show error - the assessment construct is weird, and we do not know
    // what to do.
    if (!currentGroup.next) {
      this._assessment.exit(AssessmentExitReason.Error);
      return '';
    }

    return this.getNextAvailableContentGroup(currentGroup.next.sourceId);
  }

  private unselectAllForCurrent(): void {
    const currentQuestionId =
      this._presentation.current.presentationItem.questionId;
    const currentSelection = this._selection.getForQuestion(currentQuestionId);

    // If there is at least one item that has value other than 0 then do nothing
    if (currentSelection.some(s => s.valueWhenSelected !== 0)) {
      return;
    }

    this._selection.unselectAll(currentQuestionId);
  }

  private canEnterContentGroup(
    groupOrSourceId: ContentGroupWithIndex | string | undefined,
  ): NavigationResult {
    // Deny entry to group if:
    // 1. Group does not exist
    // 2. Group has been viewed and cannot reenter
    // 3. Group has a timer and it is expired
    // 4. First item in group exhausted max views

    // If we got source ID then try to get the group objet
    if (typeof groupOrSourceId === 'string') {
      groupOrSourceId = this._presentation.getContentGroup(groupOrSourceId);
    }

    // If we have no group then we cannot enter it
    if (!groupOrSourceId) {
      return NavigationResult.NotFound;
    }

    // If the content group is 'complete' then we can always enter it regardless
    // of the configuration - should not happen, but still...
    if (groupOrSourceId.isCompleteGroup) {
      return NavigationResult.Allowed;
    }

    // Deny entry if we have viewed the group and group does not allow reentry
    if (
      !groupOrSourceId.isReentryAllowed &&
      this._progress.getContentGroupProgress(groupOrSourceId.sourceId)
        .shownToParticipantCount > 0
    ) {
      return NavigationResult.ReentryNotAllowed;
    }

    // Deny entry if group has a timer and it is expired
    if (this._timer.getTimerForGroup(groupOrSourceId.sourceId)?.isExpired) {
      return NavigationResult.TimerExpired;
    }

    // Deny entry if the first item in group does not have any views left
    if (!this.hasRemainingViews(groupOrSourceId.index)) {
      return NavigationResult.NoRemainingViews;
    }

    return NavigationResult.Allowed;
  }

  private hasRemainingViews(
    presentationItemOrIndex: PresentationItem | number,
  ): boolean {
    const target =
      typeof presentationItemOrIndex === 'number'
        ? this._presentation.presentationItems[presentationItemOrIndex]
            .presentationItem
        : presentationItemOrIndex;
    const itemProgress = this._progress.getItemProgress(target.questionId);

    // If the item exhausted views then we cannot go back
    return (
      target.maxViewsPermitted === 0 ||
      itemProgress.shownToParticipantCount < target.maxViewsPermitted
    );
  }

  private goToIndex(index?: number): void {
    if (index === undefined) return;

    if (!this.hasRemainingViews(index)) {
      this.showNavigationResult(NavigationResult.NoRemainingViews);
      return;
    }

    this._presentation.moveToIndex(index);
  }

  private getLeapGroupSourceId(): string | undefined {
    const selection = this._selection.getForQuestion(
      this._presentation.current.presentationItem.questionId,
    );

    if (selection.length !== 1) {
      return undefined;
    }

    return selection[0].onSelectedGoToContentGroupSourceId;
  }

  private async updateParticipantIdentity(): Promise<boolean> {
    const result = await this._identity.submitChanges();

    if (!result) {
      this._modal.error(
        'An error occurred while updating your contact information. Please check the provided information and try again. If the problem persists, restart the assessment from the invitation you have received from your recruiter.',
        'Contact information not updated',
      );
    }

    return result;
  }

  private showNavigationResult(status: NavigationResult): void {
    if (status === NavigationResult.Allowed) return;

    const message =
      NavigationService.getErrorMessageForNavigationResult(status);

    this._modal.error(message, 'Navigation prohibited');
  }

  private static getErrorMessageForNavigationResult(
    status: NavigationResult,
  ): string {
    switch (status) {
      case NavigationResult.Allowed:
        // We really should not show this message, but return something to satisfy
        // the compiler with all switch branches.
        return 'OK';
      case NavigationResult.NotFound:
        return 'You cannot proceed to the item because the item does not exist.';
      case NavigationResult.TimerExpired:
        return 'You cannot proceed to the item because the timer for it has expired.';
      case NavigationResult.ReentryNotAllowed:
        return 'You cannot proceed to the item because it has already been viewed and cannot be reentered.';
      case NavigationResult.NoRemainingViews:
        return 'You cannot proceed to the item because it has been viewed the maximum number of times allowed.';
      case NavigationResult.NavigationLocked:
        return 'You cannot proceed to the item because the navigation is blocked by the current item.';
      case NavigationResult.InvalidIdentity:
        return 'You cannot proceed to the item because your contact information is invalid.';
      case NavigationResult.IncorrectSelection:
        return 'You cannot proceed to the item because you have not selected the correct number of options.';
      case NavigationResult.ConfirmTerms:
        return 'You cannot proceed to the item because you have not accepted the terms and conditions.';
    }
  }
}
