import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorFactory } from '@compass/validation';
import { IdentityService } from './identity.service';
import { Requester } from '@compass/http';
import { Logger } from '@compass/logging';

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  private _supportForm: FormGroup;

  get supportForm(): FormGroup {
    return this._supportForm;
  }

  constructor(
    private readonly _identity: IdentityService,
    private readonly _requester: Requester,
    private readonly _logger: Logger,
  ) {
    this._supportForm = this.createSupportForm();
  }

  async submitForm(): Promise<boolean> {
    const model = this.supportForm.getRawValue();

    try {
      this._logger.debug('Sending support request to the server.', model);

      await this._requester.post<void>('support').send(model);

      this._identity.update({ email: model.email });

      this._logger.debug('Support ticket was created.');

      this._supportForm = this.createSupportForm();

      return true;
    } catch (error) {
      this._logger.error('Error submitting support request:', error);

      return false;
    }
  }

  private createSupportForm(): FormGroup {
    return new FormGroup({
      email: new FormControl(this._identity.participant.email, [
        ValidatorFactory.email,
        Validators.required,
      ]),
      message: new FormControl(null, [
        Validators.required,
        Validators.minLength(25),
        Validators.maxLength(500),
      ]),
    });
  }
}
