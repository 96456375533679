import { Component, inject, Input, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConditionalScoreBlockService } from '~/shared/services/ui/conditional-score-block.service';
import { DebugService } from '~/shared/services/environment/debug.service';
import { FaIconComponent } from '@compass/ui';

@Component({
  selector: 'cp-par-show-if',
  standalone: true,
  imports: [CommonModule, FaIconComponent],
  templateUrl: './show-if.component.html',
  styleUrl: './show-if.component.scss',
})
export class ShowIfComponent implements OnInit {
  private readonly _conditionalScoreService = inject(
    ConditionalScoreBlockService,
  );

  protected readonly debug = inject(DebugService);

  protected show = signal(false);

  @Input()
  minScore?: number;

  @Input()
  maxScore?: number;

  async ngOnInit(): Promise<void> {
    const result = await this._conditionalScoreService.shouldShow(
      this.minScore,
      this.maxScore,
    );

    this.show.set(result);
  }
}
