import {
  AfterContentInit,
  ContentChildren,
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { SplitScreenItemDirective } from '~/shared/directives/split-screen/split-screen-item.directive';

@Directive({
  selector: '[cpParSplitScreen]',
  standalone: true,
})
export class SplitScreenDirective
  implements OnChanges, AfterContentInit, OnDestroy
{
  /**
   * If split screen should be enabled.
   *
   * If true, attempts to create a split screen between two elements.
   */
  @Input({ required: true }) enabled: boolean = false;

  /**
   * Available height for the split screen to render in.
   *
   * Note: If this value is not static, use the availableHeightCallback
   * parameter to avoid change after check errors.
   */
  @Input() availableHeightPx?: number;

  /**
   * A callback to generate the available height of a dynamic container
   */
  @Input() availableHeightCallback?: () => number;

  @ContentChildren(SplitScreenItemDirective)
  screenItems?: QueryList<SplitScreenItemDirective>;

  @Output() isLandscape: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('window:resize', ['$event']) resize(): void {
    this.setContentSize();
  }

  private readonly fullscreenSubscriptions: Subscription[] = [];

  ngAfterContentInit(): void {
    this.screenItems?.forEach(item => {
      if (!item.allowFullscreen) return;

      this.fullscreenSubscriptions.push(
        item.toggleFullscreen.subscribe(value =>
          value ? this.setFullHeight(item) : this.setContentSize(),
        ),
      );
    });
  }

  ngOnChanges(): void {
    if (!this.screenItemsExist) return;

    this.setContentSize();
  }

  ngOnDestroy(): void {
    this.fullscreenSubscriptions.forEach(subscription =>
      subscription.unsubscribe(),
    );
  }

  /**
   * Sets the content size of each split screen item based on the available content height.
   */
  private setContentSize(): void {
    if (!this.screenItemsExist)
      throw new Error(
        'No split screen items found. Ensure cpParSplitScreenItem is used at least once.',
      );

    if (
      !this.enabled ||
      (this.screenItemsExist && this.screenItems!.length === 1)
    ) {
      // Only one item was found or split screen is disabled, set first element to the full height
      this.setFullHeight(this.screenItems!.first);
      return;
    }

    if (
      this.setDefaultIfLandscape() ||
      this.setFullHeightIfFound(item => item.fullScreen)
    )
      return;

    const contentItemSize = this.availableHeight / this.screenItems!.length;

    this.screenItems!.forEach(item => item.setHeight(contentItemSize));
  }

  /**
   * Sets a screen item to full container height
   * @param item split screen item to set as fullscreen
   */
  private setFullHeight(item: SplitScreenItemDirective): void {
    if (!this.screenItemsExist) return;

    item.setHeight(this.availableHeight);

    // set height=0 for all remaining screen items
    this.screenItems!.forEach(directive => {
      if (directive.id === item.id) return;

      directive.setHeight(0);
    });
  }

  /**
   * Sets a screen item to full height if match found based on predicate.
   * @param predicate predicate function for screen item.
   * @returns True if height has been set to full screen, false if not found or predicate condition fails
   */
  private setFullHeightIfFound(
    predicate: (
      item: SplitScreenItemDirective,
      index: number,
      array: SplitScreenItemDirective[],
    ) => boolean,
  ): boolean {
    const screenItem = this.screenItems?.find(predicate);

    if (!screenItem) return false;

    this.setFullHeight(screenItem);
    return true;
  }

  /**
   * Sets default screen item if landscape mode is detected
   *
   * @returns True if a default was set, false if not landscape or no default was found.
   */
  private setDefaultIfLandscape(): boolean {
    if (window.innerWidth < window.innerHeight) {
      this.isLandscape.emit(false);
      return false;
    }

    this.isLandscape.emit(true);
    return this.setFullHeightIfFound(item => item.defaultOnLandscape);
  }

  private get screenItemsExist(): boolean {
    return !!(this.screenItems && this.screenItems.length > 0);
  }

  private get availableHeight(): number {
    if (!this.availableHeightCallback && !this.availableHeightPx) {
      throw new Error(
        'No available height provided. Either provide a static value or a callback.',
      );
    }

    if (this.availableHeightPx) return this.availableHeightPx;

    return this.availableHeightCallback!();
  }
}
