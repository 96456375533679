import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FaIconComponent } from '@compass/ui';

@Component({
  selector: 'cp-par-expandable',
  templateUrl: './expansion.component.html',
  styleUrl: 'expansion.component.scss',
  standalone: true,
  imports: [FaIconComponent],
})
export class ExpandableComponent {
  private _isExpanded = false;

  /**
   * Hides the expand toggle button.
   *
   * Useful for temporarily disabling expansion toggling based on
   * validation or external conditions.
   */
  @Input() disabled = false;

  /**
   * Sets the default expansion value
   * @param isExpanded True if icon should show as expanded, false if otherwise
   */
  @Input({ required: true }) set isExpanded(isExpanded: boolean) {
    this._isExpanded = isExpanded;
  }

  /**
   * Emits the expanded value after expansion toggle is pressed.
   */
  @Output() expansionChanged: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  get isExpanded(): boolean {
    return this._isExpanded;
  }

  protected toggleFunc(): void {
    this._isExpanded = !this._isExpanded;
    this.expansionChanged.emit(this.isExpanded);
  }
}
