<div class="row-sm align-items-center d-flex justify-content-between w-100">
  <div class="col shortcut-wrapper">
    <div
      [cpParHotkey]="{ key: KeyboardKey.Slash, req: { requireAlt: true } }"
      class="navigation-help-text d-none d-md-block"
      (click)="toggleDrawer()"
      (keyup)="toggleDrawer()"
      tabindex="-1">
      To view keyboard shortcuts press
      <kbd>{{ deviceInfo.device.isMac ? 'Option (⌥)' : 'Alt' }}</kbd>
      + <kbd>/</kbd>
    </div>
  </div>
  <div class="col-auto d-flex justify-content-sm-center">
    <cp-par-calculator-button />
  </div>
  <div class="col d-flex justify-content-end">
    <cp-par-previous-button class="me-2" />
    <cp-par-forward-button />
  </div>
</div>

<p-drawer
  [(visible)]="showDrawer"
  [dismissible]="false"
  header="Keyboard shortcuts"
  position="right"
  transitionOptions="250ms"
  [style]="{ width: '25rem' }"
  [modal]="false">
  <cp-par-keyboard-shortcuts-help />
</p-drawer>
