<button class="btn btn-outline-danger" (click)="onReset()">
  <cp-fa-icon iconStyle="duotone">skull-cow</cp-fa-icon>
  Reset
</button>

<button class="btn btn-outline-success ms-2" (click)="onSubmit()">
  <cp-fa-icon iconStyle="duotone">check</cp-fa-icon>
  Complete
</button>

<p-accordion
  [value]="expandedAccordionItems"
  class="mt-2"
  [multiple]="true"
  (valueChange)="expanded($event)">
  <p-accordion-panel [value]="0">
    <p-accordion-header>Assessment options</p-accordion-header>
    <p-accordion-content>
      <cp-par-debug-actions />
    </p-accordion-content>
  </p-accordion-panel>

  <p-accordion-panel [value]="1">
    <p-accordion-header>Hardcore navigation</p-accordion-header>
    <p-accordion-content>
      <cp-par-debug-navigation />
    </p-accordion-content>
  </p-accordion-panel>

  <p-accordion-panel [value]="2">
    <p-accordion-header>User inactivity</p-accordion-header>
    <p-accordion-content>
      <cp-par-debug-inactivity />
    </p-accordion-content>
  </p-accordion-panel>

  <p-accordion-panel [value]="3">
    <p-accordion-header>Current presentation item</p-accordion-header>
    <p-accordion-content>
      <cp-par-debug-presentation-item-info
        [presentationItem]="presentation.current.presentationItem" />
    </p-accordion-content>
  </p-accordion-panel>

  <p-accordion-panel [value]="4">
    <p-accordion-header>Current content group</p-accordion-header>
    <p-accordion-content>
      <cp-par-debug-content-group-info
        [contentGroup]="presentation.current.contentGroup" />
    </p-accordion-content>
  </p-accordion-panel>

  <p-accordion-panel [value]="5">
    <p-accordion-header>Current content progress</p-accordion-header>
    <p-accordion-content>
      <cp-par-debug-progress
        [groupProgress]="progress.progressForCurrentGroup" />
    </p-accordion-content>
  </p-accordion-panel>

  <p-accordion-panel [value]="6">
    <p-accordion-header>Current timer</p-accordion-header>
    <p-accordion-content>
      <cp-par-debug-timer />
    </p-accordion-content>
  </p-accordion-panel>
</p-accordion>
