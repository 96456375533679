import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RedirectService } from '~/shared/services/ui/redirect.service';
import {
  AssessmentState,
  AssessmentStateService,
} from '~/shared/services/assessment/assessment-state.service';

/**
 * This component redirects to a specified URL upon page render. If multiple
 * components of this type are rendered then only the first one encountered
 * will take effect.
 */
@Component({
  selector: 'cp-par-redirect',
  standalone: true,
  imports: [CommonModule],
  template: '',
  styleUrl: './redirect.component.scss',
})
export class RedirectComponent implements OnInit {
  private readonly _redirectService = inject(RedirectService);
  private readonly _state = inject(AssessmentStateService);

  @Input()
  url?: string;

  async ngOnInit(): Promise<void> {
    if (!this.url) return;
    if (this._state.currentState !== AssessmentState.Complete) return;

    await this._redirectService.redirectToExternal(this.url);
  }
}
