import { HttpRequestMethod } from '../helpers';
import { HttpClient } from '@angular/common/http';
import { RequestBuilder } from './request-builder';

/**
 * Represents a request builder for making DELETE requests.
 * @template TResult - The type of the response data.
 * @extends RequestBuilderWithBody
 */
export class DeleteRequestBuilder<
  TResult = unknown,
> extends RequestBuilder<TResult> {
  constructor(httpClient: HttpClient) {
    super(httpClient, HttpRequestMethod.Delete);
  }
}
