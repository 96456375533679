import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DebugProviderService } from './debug-provider.service';
import { DebugOutletComponent } from './components/debug-outlet/debug-outlet.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [DebugOutletComponent],
  imports: [CommonModule, DragDropModule],
})
export class DebugModule {
  static forRoot(
    debugComponent: Type<unknown>,
  ): ModuleWithProviders<DebugModule> {
    return {
      ngModule: DebugModule,
      providers: [
        {
          provide: DebugProviderService,
          useFactory: () => new DebugProviderService(debugComponent),
        },
      ],
    };
  }
}
