<div class="form-check">
  <input
    class="form-check-input"
    type="checkbox"
    [value]="progressStore.isTrackingEnabled"
    (change)="toggleProgressPersistence()"
    id="debug_progressPersistence" />
  <label class="form-check-label" for="debug_progressPersistence">
    Progress persistence
  </label>
</div>

<div class="form-check">
  <input
    class="form-check-input"
    type="checkbox"
    [value]="session.enabled"
    (change)="toggleSessionTracking()"
    id="debug_sessionTracking" />
  <label class="form-check-label" for="debug_sessionTracking">
    Session tracking
  </label>
</div>

<div class="form-check">
  <input
    class="form-check-input"
    type="checkbox"
    #showOptionValue
    [checked]="this.debug.options.showOptionValue"
    (change)="this.debug.setOption('showOptionValue', showOptionValue.checked)"
    id="debug_showOptionValue" />
  <label class="form-check-label" for="debug_showOptionValue">
    Show option value
  </label>
</div>

<div class="form-check">
  <input
    class="form-check-input"
    type="checkbox"
    #showOptionLeapValue
    [checked]="this.debug.options.showOptionLeapValue"
    (change)="
      this.debug.setOption('showOptionLeapValue', showOptionLeapValue.checked)
    "
    id="debug_showOptionLeapValue" />
  <label class="form-check-label" for="debug_showOptionLeapValue">
    Show option leap value
  </label>
</div>

<div class="form-check">
  <input
    class="form-check-input"
    type="checkbox"
    #showResumePage
    [checked]="this.debug.options.showResumePage"
    (change)="this.debug.setOption('showResumePage', showResumePage.checked)"
    id="debug_showResumePage" />
  <label class="form-check-label" for="debug_showResumePage">
    Show resume page
  </label>
</div>

<div class="form-check">
  <input
    class="form-check-input"
    type="checkbox"
    #showHiddenConditionalBlocks
    [checked]="this.debug.options.showHiddenConditionalBlocks"
    (change)="
      this.debug.setOption(
        'showHiddenConditionalBlocks',
        showHiddenConditionalBlocks.checked
      )
    "
    id="debug_showHiddenConditionalBlocks" />
  <label class="form-check-label" for="debug_showHiddenConditionalBlocks">
    Show hidden conditional blocks
  </label>
</div>

<div class="form-check">
  <input
    class="form-check-input"
    type="checkbox"
    #highlightConditionalBlocks
    [checked]="this.debug.options.highlightConditionalBlocks"
    (change)="
      this.debug.setOption(
        'highlightConditionalBlocks',
        highlightConditionalBlocks.checked
      )
    "
    id="debug_highlightConditionalBlocks" />
  <label class="form-check-label" for="debug_highlightConditionalBlocks">
    Highlight conditional blocks
  </label>
</div>
