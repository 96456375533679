import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Renderer2,
} from '@angular/core';
import { ObjectIndex, Size } from '@compass/types';
import { getCssClasses, String } from '@compass/helpers';
import { Logger } from '@compass/logging';

/**
 * Component for FontAwesome icon usage
 */
@Component({
  selector: 'cp-fa-icon',
  templateUrl: 'fa-icon.component.html',
  styleUrls: ['fa-icon.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaIconComponent implements OnInit, OnChanges {
  private _iconName?: string;

  /**
   * Style for the icon
   */
  @Input()
  iconStyle: 'regular' | 'solid' | 'light' | 'thin' | 'duotone' = 'regular';

  /**
   * FontAwesome icon size
   */
  @Input()
  size?: Size;

  /**
   * Classic or sharp icon rendering
   */
  @Input()
  sharp: boolean = false;

  /**
   * Alternative icon name provider.
   * This will take precedence over content provider
   */
  @Input()
  icon?: string;

  /**
   * Whether icon is FontAwesome brands
   */
  @Input()
  brands: boolean = false;

  @HostBinding('class')
  get hostClasses(): ObjectIndex<boolean> {
    return getCssClasses(
      {
        cssClass: this._iconName,
        prefix: 'fa',
      },
      {
        cssClass: this.iconStyle,
        prefix: 'fa',
      },
      {
        cssClass: this.getSizeClass(this.size),
        prefix: 'fa',
      },
      {
        cssClass: 'sharp',
        condition: this.sharp && this.iconStyle !== 'duotone',
        prefix: 'fa',
      },
      {
        cssClass: 'brands',
        condition: this.brands,
        prefix: 'fa',
      },
    );
  }

  constructor(
    private readonly _elementRef: ElementRef,
    private readonly _renderer: Renderer2,
    private readonly _changeDetector: ChangeDetectorRef,
    @Optional()
    private readonly _logger?: Logger,
  ) {}

  ngOnInit(): void {
    this._iconName = this.cleanupIconName(
      String.isNullOrWhitespace(this.icon)
        ? this._elementRef.nativeElement.innerText
        : this.icon,
    );

    this.printIconWarnings();

    this.clearContent();
  }

  ngOnChanges(): void {
    if (!this.icon || this.icon === this._iconName) return;

    this._iconName = this.cleanupIconName(this.icon);

    this.printIconWarnings();
  }

  private printIconWarnings(): void {
    if (!this._iconName) {
      this._logger?.warn(
        'An icon was declared, but no icon name was provided. The icon will not be shown.',
      );
      return;
    }

    if (this.iconStyle === 'duotone' && this.sharp) {
      this._logger?.warn(
        `Duotone icon style is not supported for sharp display. Icon [${this._iconName}] will be rendered in classic display.`,
      );
    }
  }

  private clearContent(): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'innerHTML', '');
    this._changeDetector.detectChanges();
  }

  private cleanupIconName(name?: string): string | undefined {
    if (!name) return undefined;

    if (name.startsWith('fa-')) {
      name = name.replace('fa-', '');
    }

    name = name.trim();

    return name;
  }

  private getSizeClass(size?: Size): string | undefined {
    if (!size) return undefined;

    switch (size) {
      case 'xsmall':
        return '2xs';
      case 'small':
        return 'xs';
      case 'medium':
        return 'sm';
      case 'large':
        return 'lg';
      case 'xlarge':
        return 'xl';
      case 'xxlarge':
        return '2xl';
    }
  }
}
