<div
  class="d-inline-block"
  ngbTooltip="Complete the form to request help"
  [disableTooltip]="submitInProgress()">
  <button
    class="btn btn-success py-3 px-4"
    [disabled]="!support.supportForm.valid"
    [cpParHotkey]="KeyboardKey.ArrowRight"
    (click)="submitSupportForm()">
    @if (submitInProgress()) {
      <div class="spinner-border spinner-border-sm"></div>
    } @else {
      Submit
    }
  </button>
</div>

<ng-template #supportFormSubmittedTemplate let-email>
  <p>Your request has been sent to the Assessment Support Team.</p>
  <p>
    We will respond to you at <strong>{{ email }}</strong> within one business
    day.
  </p>
  <p>
    You may close this browser window or click the button below to resume your
    assessment.
  </p>
</ng-template>
