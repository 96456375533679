import {
  Component,
  inject,
  signal,
  TemplateRef,
  viewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { SupportService } from '../../services/ui/support.service';
import { KeyboardKey } from '../../services/ui/keyboard.service';
import { HotkeyDirective } from '../../directives/hotkey.directive';
import { ModalService } from '@compass/notifications';
import { AssessmentStateService } from '../../services/assessment/assessment-state.service';

@Component({
  selector: 'cp-par-submit-support-button',
  standalone: true,
  imports: [CommonModule, NgbTooltip, HotkeyDirective],
  templateUrl: './submit-support-button.component.html',
})
export class SubmitSupportButtonComponent {
  private readonly _modal = inject(ModalService);
  private readonly _state = inject(AssessmentStateService);

  protected readonly KeyboardKey = KeyboardKey;
  protected readonly support = inject(SupportService);
  protected readonly submitInProgress = signal(false);

  protected readonly supportFormSubmittedTemplate = viewChild.required(
    'supportFormSubmittedTemplate',
    { read: TemplateRef<unknown> },
  );

  protected async submitSupportForm(): Promise<void> {
    if (this.submitInProgress()) {
      return;
    }

    this.submitInProgress.set(true);

    const result = await this.support.submitForm();

    this.submitInProgress.set(false);

    if (!result) {
      this._modal.error(
        'The support message could not be delivered to us. Please try again.',
        'Support request failed',
      );
      return;
    }

    const successModal = this._modal.success(
      this.supportFormSubmittedTemplate(),
      'Support request received',
      {
        confirmText: 'Resume Assessment',
        contentContext: {
          $implicit: this.support.supportForm.value['email'],
        },
      },
    );

    if (await successModal.result) {
      this._state.goBack();
    }
  }
}
