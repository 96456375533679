import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { DoNotFocusDirective, FaIconComponent } from '@compass/ui';
import { CalculatorService } from '../../services/ui/calculator.service';
import { DeviceInfoService } from '@compass/environment';

@Component({
  selector: 'cp-par-calculator-button',
  standalone: true,
  imports: [CommonModule, NgbTooltip, DoNotFocusDirective, FaIconComponent],
  templateUrl: './calculator-button.component.html',
  styleUrls: ['./calculator-button.component.scss'],
})
export class CalculatorButtonComponent {
  constructor(
    protected readonly calculator: CalculatorService,
    protected readonly deviceInfo: DeviceInfoService,
  ) {}

  protected readonly window = window;
}
