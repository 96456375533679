import { inject, Injectable } from '@angular/core';
import { Requester } from '@compass/http';

type ShowResult = { minScore?: number; maxScore?: number; shouldShow: boolean };

@Injectable({
  providedIn: 'root',
})
export class ConditionalScoreBlockService {
  private readonly _requester = inject(Requester);
  private readonly _valuesToShow: ShowResult[] = [];

  async shouldShow(minScore?: number, maxScore?: number): Promise<boolean> {
    const canShow = this._valuesToShow.find(
      result => minScore === result.minScore && maxScore === result.maxScore,
    );
    if (canShow) {
      return canShow.shouldShow;
    }

    const result = await this.loadFromServer(minScore, maxScore);

    this._valuesToShow.push({ minScore, maxScore, shouldShow: result });

    return result;
  }

  private async loadFromServer(
    minScore?: number,
    maxScore?: number,
  ): Promise<boolean> {
    const result = await this._requester
      .get<{ canShow: boolean }>('content/show')
      .addQuery('minValue', minScore)
      .addQuery('maxValue', maxScore)
      .send();

    return result.isSuccess ? result.data!.canShow : false;
  }
}
