import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_API_URL } from '../helpers';
import {
  GetRequestBuilder,
  PutRequestBuilder,
  PostRequestBuilder,
  RequestBuilder,
  RequestBuilderWithBody,
  DeleteRequestBuilder,
} from '../builders';

/**
 * Service for creating HTTP requests
 */
@Injectable()
export class Requester {
  constructor(
    @Inject(BASE_API_URL)
    private readonly _baseUrl: string,
    private readonly _httpClient: HttpClient,
  ) {}

  /**
   * Starts a GET request
   * @param route Optional base route
   */
  get<TResult = unknown>(route?: string): RequestBuilder<TResult> {
    const builder = new GetRequestBuilder<TResult>(this._httpClient);

    this.initBuilder(builder, route);

    return builder;
  }

  /**
   * Create a PUT request builder with a specified route.
   *
   * @param {string} route - The route to send the PUT request to.
   * @return {RequestBuilderWithBody} - A PUT request builder with specified route.
   */
  put<TResult = unknown>(route?: string): RequestBuilderWithBody<TResult> {
    const builder = new PutRequestBuilder<TResult>(this._httpClient);

    this.initBuilder(builder, route);

    return builder;
  }

  /**
   * Creates a POST request builder.
   *
   * @param {string} route - The optional route for the request.
   * @return {RequestBuilderWithBody<TResult>} - An instance of RequestBuilderWithBody.
   */
  post<TResult = unknown>(route?: string): RequestBuilderWithBody<TResult> {
    const builder = new PostRequestBuilder<TResult>(this._httpClient);

    this.initBuilder(builder, route);

    return builder;
  }

  /**
   * Initiates a DELETE HTTP request with the specified route.
   *
   * @param {string} [route] - The optional route to perform the DELETE request on.
   * @return {RequestBuilderWithBody<TResult>} The request builder configured for the DELETE request.
   */
  delete<TResult = unknown>(route?: string): RequestBuilder<TResult> {
    const builder = new DeleteRequestBuilder<TResult>(this._httpClient);

    this.initBuilder(builder, route);

    return builder;
  }

  private initBuilder(builder: RequestBuilder, route?: string): void {
    builder.addRoute(this._baseUrl);

    if (route) {
      builder.addRoute(route);
    }
  }
}
