import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DebugActionsComponent } from '../debug-actions/debug-actions.component';
import { DebugPresentationItemInfoComponent } from '../debug-presentation-item-info/debug-presentation-item-info.component';
import { PresentationService } from '../../../services/assessment/presentation.service';
import { DebugContentGroupInfoComponent } from '../debug-content-group-info/debug-content-group-info.component';
import { DebugProviderService } from '@compass/environment';
import { DebugNavigationComponent } from '../debug-navigation/debug-navigation.component';
import { DebugTimerComponent } from '../debug-timer/debug-timer.component';
import { DebugProgressComponent } from '../debug-progress/debug-progress.component';
import { ProgressService } from '../../../services/assessment/progress.service';
import { DebugInactivityComponent } from '../debug-inactivity/debug-inactivity.component';
import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionPanel,
} from 'primeng/accordion';
import { FaIconComponent } from '@compass/ui';
import { ModalService } from '@compass/notifications';
import { DebugClient } from '~/shared/clients/debug.client';
import { AssessmentService } from '~/shared/services/assessment/assessment.service';

const DEBUG_STORAGE_KEY = 'expanded-debug-items';

@Component({
  selector: 'cp-par-debug-window',
  standalone: true,
  imports: [
    CommonModule,
    DebugActionsComponent,
    DebugPresentationItemInfoComponent,
    DebugContentGroupInfoComponent,
    DebugNavigationComponent,
    DebugTimerComponent,
    DebugProgressComponent,
    DebugInactivityComponent,
    Accordion,
    AccordionPanel,
    AccordionHeader,
    AccordionContent,
    FaIconComponent,
  ],
  templateUrl: './debug-window.component.html',
  styleUrls: ['./debug-window.component.scss'],
})
export class DebugWindowComponent implements OnInit {
  protected expandedAccordionItems: number[] = [];

  constructor(
    protected readonly progress: ProgressService,
    protected readonly presentation: PresentationService,
    private readonly _debug: DebugProviderService,
    private readonly _modal: ModalService,
    private readonly _debugClient: DebugClient,
    private readonly _assessment: AssessmentService,
  ) {}

  ngOnInit(): void {
    this.expandedAccordionItems =
      this._debug.getData<number[]>(DEBUG_STORAGE_KEY) ?? [];
  }

  protected async onReset(): Promise<void> {
    if (
      (await this._modal.confirm(
        'Do you want to reset the assessment? This will clear all progress and restart the assessment. This cannot be undone.',
        'Hold your cows!',
        { style: 'danger', confirmText: 'Reset' },
      )) &&
      (await this._debugClient.reset())
    ) {
      location.reload();
    }
  }

  protected async onSubmit(): Promise<void> {
    if (
      await this._modal.confirm(
        'Do you want to submit the assessment? This will upload any progress and end the assessment.',
        'Hold your cows!',
        { confirmText: 'Submit' },
      )
    ) {
      await this._assessment.finalize();
      location.reload();
    }
  }

  protected expanded(data: unknown): void {
    if (
      data === undefined ||
      !Array.isArray(data) ||
      !data.every(Number.isInteger)
    )
      return;

    this._debug.storeData(DEBUG_STORAGE_KEY, data);
  }
}
