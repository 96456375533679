@if (debug.enabled) {
  @if (show() || debug.options.showHiddenConditionalBlocks) {
    @if (debug.options.highlightConditionalBlocks) {
      <div class="conditional-wrapper" [class.conditional-hidden]="!show()">
        <div class="conditional-title">
          <strong class="d-block text-center">CONDITIONAL SECTION</strong>
          <cp-fa-icon [icon]="show() ? 'eye' : 'eye-slash'" />
          This section is {{ show() ? 'visible' : 'hidden' }}. This section is
          visible when minimum score is
          {{ minScore === undefined ? '-∞' : minScore }} and maximum score is
          {{ maxScore === undefined ? '∞' : maxScore }}.
        </div>
        <ng-container *ngTemplateOutlet="contentTemplate" />
      </div>
    } @else {
      <ng-container *ngTemplateOutlet="contentTemplate" />
    }
  }
} @else {
  @if (show()) {
    <ng-container *ngTemplateOutlet="contentTemplate" />
  }
}

<ng-template #contentTemplate>
  <ng-content />
</ng-template>
