import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InactivityService } from '../../../services/ui/inactivity.service';
import { DebugProviderService } from '@compass/environment';
import { ContentClient } from '../../../clients/content.client';

const DEBUG_STORAGE_KEY = 'inactivity-enabled';

@Component({
  selector: 'cp-par-debug-inactivity',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './debug-inactivity.component.html',
})
export class DebugInactivityComponent {
  constructor(
    private readonly _debug: DebugProviderService,
    protected readonly client: ContentClient,
    protected readonly inactivity: InactivityService,
  ) {
    const inactivityEnabled = this._debug.getData<boolean>(DEBUG_STORAGE_KEY);

    if (inactivityEnabled === undefined) return;

    this.inactivity.enabled = inactivityEnabled;
  }

  protected toggleInactivity(): void {
    this.inactivity.enabled = !this.inactivity.enabled;

    if (this.inactivity.enabled) {
      this.inactivity.start();
    }

    this._debug.storeData(DEBUG_STORAGE_KEY, this.inactivity.enabled);
  }
}
