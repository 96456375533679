import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../security/authentication.service';
import { StartCode } from '../../helpers/start-code';
import { ApplicationEnvironment } from '@compass/environment';
import { ModalService } from '@compass/notifications';
import { Logger } from '@compass/logging';

/**
 * Service for redirecting to different URLs.
 */
@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  private _externalRedirectInProgress = false;

  constructor(
    private readonly _router: Router,
    private readonly _authService: AuthenticationService,
    private readonly _modal: ModalService,
    private readonly _environment: ApplicationEnvironment,
    private readonly _logger: Logger,
  ) {}

  /**
   * Redirects to a specified path within the application.
   * If a startCode is provided, it appends it to the path if not already present.
   *
   * @param {string} path - The path to redirect to.
   * @param {string} [startCode] - The startCode to append to the path (optional).
   *                              If not provided, it tries to get start code from current session.
   * @return {Promise<boolean>} - A promise that resolves to true if the navigation was successful, false otherwise.
   */
  redirect(path: string, startCode?: string): Promise<boolean> {
    startCode ??= this._authService.startCode;

    if (startCode && !path.includes(startCode)) {
      path = StartCode.addToQueryString(path, startCode);
    }

    return this._router.navigateByUrl(path);
  }

  /**
   * Redirects the user to an external URL. Optionally displays a prompt to the user for confirmation
   * before proceeding with the redirection.
   *
   * @param {string} url - The URL to which the user will be redirected.
   * @return {Promise<void>} A promise that resolves when the redirection is initiated or aborted.
   */
  async redirectToExternal(url: string): Promise<void> {
    if (this._externalRedirectInProgress) {
      if (this._environment.isDevelopment) {
        this._logger.debug(
          `Refused to redirect to '${url}' because another redirect already in progress.`,
        );
      }

      return;
    }

    this._externalRedirectInProgress = true;

    // If we are in development, only continue with the normal flow
    // after explicit developer consent.
    if (
      this._environment.isDevelopment &&
      !(await this.displayDevelopRedirectPrompt(url))
    ) {
      return;
    }

    window.location.href = url;
  }

  private displayDevelopRedirectPrompt(url: string): Promise<boolean> {
    const promptText = `The application requested a redirect to an external URL: ${url}. You can continue with the normal flow of the redirect or you can cancel it now to stay on this page.`;

    return this._modal.confirm(promptText, 'DEVELOPMENT: Redirect in progress');
  }
}
