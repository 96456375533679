import { Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PresentationService } from '../../services/assessment/presentation.service';
import { IdentityService } from '../../services/ui/identity.service';
import { HotkeyDirective } from '../../directives/hotkey.directive';
import { KeyboardKey } from '../../services/ui/keyboard.service';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { VideoService } from '../../services/ui/video.service';
import {
  NavigationResult,
  NavigationService,
} from '~/shared/services/assessment/navigation.service';

@Component({
  selector: 'cp-par-next-button',
  standalone: true,
  imports: [CommonModule, HotkeyDirective, NgbTooltip],
  templateUrl: './next-button.component.html',
})
export class NextButtonComponent {
  protected readonly KeyboardKey = KeyboardKey;
  protected readonly NavigationResult = NavigationResult;

  protected readonly isBusy = signal(false);

  protected get tooltipText(): string | undefined {
    if (this.navigation.canGoNext() === NavigationResult.Allowed)
      return undefined;

    if (this._videoService.forwardNavigationDisabled()) {
      return 'Finish watching the video to continue';
    }

    // When we are showing identity page
    if (!this._identity.isValid()) {
      return 'Complete the form to continue';
    }

    return 'Answer the current question to continue';
  }

  protected get showButton(): boolean {
    switch (this.navigation.canGoNext()) {
      case NavigationResult.NotFound:
      case NavigationResult.TimerExpired:
      case NavigationResult.ReentryNotAllowed:
      case NavigationResult.NoRemainingViews:
        return false;
      default:
        return !this.presentation.current.isLastInAssessment;
    }
  }

  constructor(
    protected readonly presentation: PresentationService,
    protected readonly navigation: NavigationService,
    private readonly _identity: IdentityService,
    private readonly _videoService: VideoService,
  ) {}

  protected async onClick(): Promise<void> {
    if (this.navigation.canGoNext() !== NavigationResult.Allowed) {
      return;
    }

    this.isBusy.set(true);
    // If nothing else happened progress the item
    await this.navigation.goNext();

    this.isBusy.set(false);
  }
}
