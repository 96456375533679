import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AssessmentState,
  AssessmentStateService,
} from '../../services/assessment/assessment-state.service';
import { NextButtonComponent } from '../next-button/next-button.component';
import { SubmitSupportButtonComponent } from '../submit-support-button/submit-support-button.component';
import { ExpiredButtonComponent } from '../expired-button/expired-button.component';
import { ResumeButtonComponent } from '../resume-button/resume-button.component';

/**
 * ForwardButtonComponent is a standalone Angular component responsible for rendering
 * a forward navigation button in the application. It provides functionality
 * to progress to the next state or screen within the context of an assessment or workflow.
 */
@Component({
  selector: 'cp-par-forward-button',
  standalone: true,
  imports: [
    CommonModule,
    NextButtonComponent,
    SubmitSupportButtonComponent,
    ExpiredButtonComponent,
    ResumeButtonComponent,
  ],
  templateUrl: './forward-button.component.html',
  styleUrl: './forward-button.component.scss',
})
export class ForwardButtonComponent {
  protected readonly AssessmentState = AssessmentState;
  protected readonly state = inject(AssessmentStateService);
}
