<div class="d-flex align-items-center flex-fill header-content">
  <cp-fa-icon *ngIf="dialogStyle === 'danger'"> circle-exclamation </cp-fa-icon>
  <cp-fa-icon *ngIf="dialogStyle === 'success'"> circle-check </cp-fa-icon>
  <cp-fa-icon *ngIf="dialogStyle === 'info'"> circle-info </cp-fa-icon>

  <ng-container *ngIf="isSimpleTitle">
    <h5 class="p-0 m-0 d-flex align-items-center">
      <strong>{{ title }}</strong>
    </h5>
  </ng-container>

  <ng-container *ngIf="!isSimpleTitle">
    <ng-container
      *ngTemplateOutlet="$any(title); context: titleContext ?? {}" />
  </ng-container>
</div>
