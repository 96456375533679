import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PresentationService } from '../../services/assessment/presentation.service';
import {
  AssessmentState,
  AssessmentStateService,
} from '../../services/assessment/assessment-state.service';
import { KeyboardKey } from '../../services/ui/keyboard.service';
import { HotkeyDirective } from '../../directives/hotkey.directive';
import {
  NavigationResult,
  NavigationService,
} from '~/shared/services/assessment/navigation.service';

@Component({
  selector: 'cp-par-previous-button',
  standalone: true,
  imports: [CommonModule, HotkeyDirective],
  templateUrl: './previous-button.component.html',
})
export class PreviousButtonComponent {
  protected readonly KeyboardKey = KeyboardKey;

  get showButton(): boolean {
    switch (this._state.currentState) {
      case AssessmentState.Complete:
      case AssessmentState.InProgress:
        return this._navigationService.canGoBack() === NavigationResult.Allowed;
      case AssessmentState.Support:
        return true;
      default:
        return false;
    }
  }

  get buttonText(): string {
    switch (this._state.currentState) {
      case AssessmentState.Complete:
      case AssessmentState.InProgress:
        return this._presentation.current.presentationItem
          .navigationPreviousLabel;
      case AssessmentState.Support:
        return 'Resume Assessment';
    }

    return '';
  }

  constructor(
    private readonly _presentation: PresentationService,
    private readonly _state: AssessmentStateService,
    private readonly _navigationService: NavigationService,
  ) {}

  async previousClick(): Promise<void> {
    switch (this._state.currentState) {
      case AssessmentState.Complete:
      case AssessmentState.InProgress:
        if (this._navigationService.canGoBack() === NavigationResult.Allowed) {
          await this._navigationService.goBack();
        }
        break;
      case AssessmentState.Support:
        this._state.goBack();
        break;
    }
  }
}
