// @sonar-disable

/**
 * Class containing regex patters for common use
 */
export class Regex {
  /**
   * Email regex pattern
   */
  static readonly email =
    // eslint-disable-next-line no-control-regex
    /^(?:[a-z0-9#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

  static readonly phone = /^(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;

  /**
   * Assessment start code regex pattern
   */
  static readonly startCode = /Z[A-Za-z0-9]{10}/;
}
