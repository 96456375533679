import { Injectable, OnDestroy } from '@angular/core';
import { DebugProviderService } from '@compass/environment';

export interface DebugOptions {
  progressPersistence: boolean;
  sessionTracking: boolean;
  showOptionLeapValue: boolean;
  showOptionValue: boolean;
  showResumePage: boolean;
  showHiddenConditionalBlocks: boolean;
  highlightConditionalBlocks: boolean;
}

const defaultDebugOptions: DebugOptions = {
  progressPersistence: true,
  sessionTracking: true,
  showOptionValue: true,
  showOptionLeapValue: true,
  showResumePage: true,
  showHiddenConditionalBlocks: false,
  highlightConditionalBlocks: false,
};

@Injectable({
  providedIn: 'root',
})
export class DebugService implements OnDestroy {
  private readonly _enableSub;
  private _options: DebugOptions = defaultDebugOptions;

  get options(): Readonly<DebugOptions> {
    return this._options;
  }

  get enabled(): boolean {
    return this._debugProvider.enabled;
  }

  constructor(private readonly _debugProvider: DebugProviderService) {
    this._enableSub = this._debugProvider.enabled$.subscribe(
      this.onEnableToggle.bind(this),
    );
  }

  ngOnDestroy(): void {
    this._enableSub.unsubscribe();
  }

  setOption(key: keyof DebugOptions, value: unknown): void {
    // @ts-expect-error Store whatever
    this._options[key] = value;

    this._debugProvider.storeData('options', this._options);
  }

  private onEnableToggle(enabled: boolean): void {
    if (!enabled) return;

    this._options = {
      ...this._options,
      ...this._debugProvider.getData('options'),
    };
  }
}
