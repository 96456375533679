import { Injectable } from '@angular/core';
import { ContentItemOption } from '../../models/assessment/content-item-option';
import { Logger } from '@compass/logging';
import { ContentClient } from '../../clients/content.client';
import { AssessmentContentService } from './assessment-content.service';
import { PresentationItemState } from '../../models/assessment/presentation-item-state';
import { Subject } from 'rxjs';

/**
 * Represents a selection of options for a specific question.
 * @interface QuestionSelection
 */
export interface QuestionSelection {
  questionId: string;
  selections: ContentItemOption[];
}

/**
 * Service that tracks selected options for questions
 */
@Injectable({
  providedIn: 'root',
})
export class SelectionService {
  private readonly _selectionUpdated = new Subject<QuestionSelection>();
  private readonly _selections = new Map<string, ContentItemOption[]>();

  readonly selectionUpdated$ = this._selectionUpdated.asObservable();

  constructor(
    contentClient: ContentClient,
    private readonly content: AssessmentContentService,
    private readonly _logger: Logger,
  ) {
    this.init(contentClient.data.progress?.presentationItemStates);
  }

  /**
   * Sets a selection for a particular question
   * @param questionId Question ID
   * @param options Options to select for question
   */
  select(questionId: string, ...options: ContentItemOption[]): void {
    if (options.length === 0) return;

    const selections = this._selections.has(questionId)
      ? [...this.getForQuestion(questionId), ...options]
      : options;

    this._selections.set(questionId, selections);
    this._selectionUpdated.next({ questionId, selections });
    this.logSelections(questionId);
  }

  /**
   * Removes selection from question
   * @param questionId Question ID
   * @param options Options to remove
   */
  unselect(questionId: string, ...options: ContentItemOption[]): void {
    if (!this.hasSelections(questionId) || options.length === 0) return;

    const selections = this.getForQuestion(questionId).filter(
      o => !options.includes(o),
    );

    // If the selections are the same then do nothing
    if (selections.length === this.getForQuestion(questionId).length) return;

    this._selections.set(questionId, selections);
    this._selectionUpdated.next({ questionId, selections });
    this.logSelections(questionId);
  }

  /**
   * Unselects all selected options or items associated with the specified question.
   *
   * @param {string} questionId - The unique identifier of the question for which all selections should be cleared.
   * @return {void} This method does not return any value.
   */
  unselectAll(questionId: string): void {
    this.update(questionId);
  }

  /**
   * Clears all selections and replaces them with current selection.
   *
   * @param {number} questionId - The ID of the question to be updated.
   * @param {...ContentItemOption[]} selections - The new selections for the question.
   * @returns {void}
   */
  update(questionId: string, ...selections: ContentItemOption[]): void {
    this._selections.set(questionId, selections);

    this._selectionUpdated.next({ questionId, selections });
    this.logSelections(questionId);
  }

  /**
   * Checks whether question has at least one selected option
   * @param questionId Question ID to check
   */
  hasSelections(questionId: string): boolean {
    return this.getForQuestion(questionId).length > 0;
  }

  /**
   * Gets selected options for a question
   * @param questionId Question ID
   * @returns Selected options for question
   */
  getForQuestion(questionId: string): ContentItemOption[] {
    const selections = this._selections.get(questionId);

    return selections ?? [];
  }

  private init(startStates?: PresentationItemState[]): void {
    if (!startStates || startStates?.length === 0) return;

    for (const itemState of startStates) {
      const options = this.findOptionsForIds(
        itemState.questionId,
        itemState.selections,
      );

      this._selections.set(itemState.questionId, options);
    }
  }

  private findOptionsForIds(
    questionId: string,
    contentItemIds: string[],
  ): ContentItemOption[] {
    for (const contentGroup of this.content.contentGroups) {
      for (const contentItem of contentGroup.presentationItems) {
        if (contentItem.questionId !== questionId) continue;

        return contentItem.options.filter(o =>
          contentItemIds.includes(o.questionOptionId),
        );
      }
    }

    return [];
  }

  private logSelections(questionId: string): void {
    this._logger.debug(
      `Selection for question [${questionId}] was updated. Currently selected options are: `,
      this.getForQuestion(questionId),
    );
  }
}
