import { Injectable } from '@angular/core';
import { ContentClient } from '../../clients/content.client';
import { Participant } from '../../models/participant/participant';
import { Logger } from '@compass/logging';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ValidatorFactory } from '@compass/validation';
import { Requester } from '@compass/http';
import { EphemeralStorageService } from '@compass/storage';
import { EphemeralStorageKey } from '../../constants/ephemeral-storage-key';

export type IdentityFormConfig = {
  [Property in keyof Participant]: {
    required: boolean;
  };
};

export type IdentityFormInstance = Readonly<
  Partial<{
    [Property in keyof Participant]: FormControl;
  }> & {
    formId: string;
    instance: FormGroup;
    destroy: () => void;
  }
>;

/**
 * Service class for managing participant identity.
 */
@Injectable({
  providedIn: 'root',
})
export class IdentityService {
  private readonly _forms = new Map<string, IdentityFormInstance>();

  private _participant: Participant;

  /**
   * Participant information.
   *
   * @returns {Participant} Participant information.
   */
  get participant(): Readonly<Participant> {
    return this._participant;
  }

  constructor(
    contentClient: ContentClient,
    private readonly _logger: Logger,
    private readonly _requester: Requester,
    private readonly _ephemeralStorage: EphemeralStorageService,
    private readonly _formBuilder: FormBuilder,
  ) {
    this._participant = contentClient.data.participant;

    this.storeIdentityInEphemeralStorage();
  }

  isValid(): boolean {
    for (const form of this._forms.values()) {
      if (!form.instance.valid) {
        return false;
      }
    }

    return true;
  }

  isDirty(): boolean {
    for (const form of this._forms.values()) {
      if (form.instance.dirty) {
        return true;
      }
    }

    return false;
  }

  /**
   * Sends the changes made to the participant's identity to the server for storage.
   *
   * @returns {Promise<boolean>} Promise that resolves to true if the changes were successfully submitted, false otherwise.
   */
  async submitChanges(): Promise<boolean> {
    if (!this.isDirty()) {
      return true;
    }

    const model = this.collectCurrentValues();
    // Update the phone number to have '+1' prefix
    if (model.phone) {
      model.phone = '+1' + model.phone;
    }

    try {
      this._logger.debug(
        'Sending updated participant identity to the server.',
        model,
      );

      const result = await this._requester
        .post('identification/participant')
        .send(model);

      if (!result.isSuccess) {
        this._logger.error('Failed to submit identity changes:', result.errors);
        return false;
      }

      this._logger.debug('Identity for participant was updated.');

      this.update(model);

      // Clear all the forms
      for (const form of this._forms.values()) {
        form.instance.reset();
      }

      this.storeIdentityInEphemeralStorage();

      return true;
    } catch (error) {
      this._logger.error('Error submitting identity changes:', error);

      return false;
    }
  }

  createForm(config: Partial<IdentityFormConfig>): IdentityFormInstance {
    const controls: { [key: string]: FormControl } = {};

    if (config.nameFirst) {
      controls['nameFirst'] = this._formBuilder.control(
        this.participant.nameFirst,
        config.nameFirst.required ? [Validators.required] : [],
      );
    }

    if (config.nameLast) {
      controls['nameLast'] = this._formBuilder.control(
        this.participant.nameLast,
        config.nameLast.required ? [Validators.required] : [],
      );
    }

    if (config.email) {
      controls['email'] = this._formBuilder.control(
        this.participant.email,
        config.email.required
          ? [Validators.required, Validators.email]
          : [Validators.email],
      );
    }

    if (config.phone) {
      controls['phone'] = this._formBuilder.control(
        this.participant.phone?.replace('+1', ''),
        config.phone.required
          ? [Validators.required, ValidatorFactory.phone]
          : [ValidatorFactory.phone],
      );
    }

    const id = crypto.randomUUID();
    const form: IdentityFormInstance = {
      formId: id,
      instance: this._formBuilder.group(controls),
      destroy: () => this._forms.delete(id),
      ...controls,
    };

    this._forms.set(id, form);

    return form;
  }

  /**
   * Updates the participant's information with the provided partial data.
   *
   * @param {Partial<Participant>} participant - An object containing the participant properties to be updated.
   * @return {void} This method does not return a value.
   */
  update(participant: Partial<Participant>): void {
    this._participant = { ...this._participant, ...participant };
  }

  private collectCurrentValues(): Participant {
    let model = { ...this.participant };

    for (const form of this._forms.values()) {
      model = {
        ...model,
        ...form.instance.getRawValue(),
      };
    }

    return model;
  }

  private storeIdentityInEphemeralStorage(): void {
    this._ephemeralStorage.store(
      EphemeralStorageKey.Participant,
      this.participant,
    );
  }
}
