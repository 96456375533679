import { Injectable, OnDestroy } from '@angular/core';
import { ContentGroup } from '../../models/assessment/content-group';
import { ItemTracker } from '../../models/assessment/item-tracker';
import { Logger } from '@compass/logging';
import { PresentationItemState } from '../../models/assessment/presentation-item-state';
import { ContentClient } from '../../clients/content.client';
import {
  PresentationItemChangeArgs,
  PresentationService,
} from './presentation.service';
import { GroupItemTracker } from '~/shared/models/assessment/group-item-tracker';

enum ElementIncrementsViewCount {
  vimeo = 'cp-vimeo',
}

export interface GroupProgress {
  readonly progressId: string;
  readonly progress: number;
  readonly shown: number;
  readonly total: number;
}

const GLOBAL_GROUP = 'global';

@Injectable({
  providedIn: 'root',
})
export class ProgressService implements OnDestroy {
  private readonly _itemTrackersForProgressBarGroup: GroupItemTracker[] = [];
  private readonly _itemTrackersForContentGroup: GroupItemTracker[] = [];
  private readonly _presentationItemChangeSub;

  private _progressForCurrentGroup: GroupProgress;
  private _currentItemTracker: ItemTracker;

  get progressForCurrentGroup(): GroupProgress {
    return this._progressForCurrentGroup;
  }

  get currentItemTracker(): ItemTracker {
    return this._currentItemTracker;
  }

  constructor(
    contentClient: ContentClient,
    private readonly _logger: Logger,
    private readonly _presentation: PresentationService,
  ) {
    this.init(
      contentClient.data.content,
      contentClient.data.progress?.presentationItemStates,
    );

    this._progressForCurrentGroup = this.getGroupProgress(
      this._presentation.current.contentGroup.progressBarSourceId,
    );
    this._currentItemTracker = this.getItemProgress(
      this._presentation.current.presentationItem.questionId,
    );
    this._presentationItemChangeSub =
      this._presentation.presentationItemChange$.subscribe(
        this.onPresentationItemChange.bind(this),
      );
  }

  ngOnDestroy(): void {
    this._presentationItemChangeSub.unsubscribe();
  }

  getItemProgress(questionId: string): ItemTracker {
    const question = this._itemTrackersForProgressBarGroup
      .find(g => g.groupId === GLOBAL_GROUP)!
      .getItemTracker(questionId);

    if (!question) {
      this._logger.warn(
        `No tracker was found for question [${questionId}]. A tracker with no effect will be used.`,
      );
      return ItemTracker.empty();
    }

    return question;
  }

  updateProgress(): void {
    this._progressForCurrentGroup = this.getGroupProgress(
      this._presentation.current.contentGroup.progressBarSourceId,
    );
  }

  getContentGroupProgress(contentGroupSourceId: string): GroupItemTracker {
    const tracker = this._itemTrackersForContentGroup.find(
      g => g.groupId === contentGroupSourceId,
    );

    if (!tracker) {
      this._logger.warn(
        `No tracker was found for content group [${contentGroupSourceId}]. A tracker with no effect will be used.`,
      );

      return GroupItemTracker.empty();
    }

    return tracker;
  }

  private getGroupProgress(progressGroupId: string): GroupProgress {
    const group = this._itemTrackersForProgressBarGroup.find(
      g => g.groupId === progressGroupId,
    );

    if (!group || group.totalCount === 0) {
      this._logger.warn(
        `No progress was found for group [${progressGroupId}].`,
      );
      return { progressId: 'N/A', progress: 0, total: 0, shown: 0 };
    }

    const shown = group.items.filter(q => q.shownToParticipantCount > 0).length;

    return {
      progressId: progressGroupId,
      progress: shown / group.totalCount,
      shown,
      total: group.totalCount,
    };
  }

  private onPresentationItemChange(change: PresentationItemChangeArgs): void {
    this._currentItemTracker.stopTracking();

    this._currentItemTracker = this.getItemProgress(change.newItem.questionId);

    this._currentItemTracker.startTracking(
      this.canIncrementShownCount(change.newItem.contentBody),
    );

    this.updateProgress();
  }

  canIncrementShownCount(contentBody: string): boolean {
    const enumValues = Object.values(ElementIncrementsViewCount);
    return !enumValues.some(enumVal =>
      new RegExp(enumVal, 'g').test(contentBody),
    );
  }

  private init(
    contentGroups: ContentGroup[],
    itemStates?: PresentationItemState[],
  ): void {
    // Create global progress group with empty ID
    const globalTrackers: ItemTracker[] = [];
    const itemTrackersForProgressBar = new Map<string, ItemTracker[]>();

    for (const contentGroup of contentGroups) {
      const contentGroupTrackers: ItemTracker[] = [];

      for (const item of contentGroup.presentationItems) {
        const itemState = itemStates?.find(
          is => is.questionId === item.questionId,
        );
        const tracker = new ItemTracker(
          item.questionId,
          itemState?.shownToCandidateCount,
          itemState?.secondsVisibleCount,
        );

        // Add tracker to global group
        globalTrackers.push(tracker);

        // Add tracker to content group
        contentGroupTrackers.push(tracker);

        // Add tracker to its progress group
        if (itemTrackersForProgressBar.has(contentGroup.progressBarSourceId)) {
          itemTrackersForProgressBar
            .get(contentGroup.progressBarSourceId)!
            .push(tracker);
        } else {
          itemTrackersForProgressBar.set(contentGroup.progressBarSourceId, [
            tracker,
          ]);
        }
      }

      const groupTracker = new GroupItemTracker(
        contentGroup.sourceId,
        contentGroupTrackers,
      );
      this._itemTrackersForContentGroup.push(groupTracker);
    }

    itemTrackersForProgressBar.set(GLOBAL_GROUP, globalTrackers);

    for (const [progressGroupId, trackers] of itemTrackersForProgressBar) {
      const group = new GroupItemTracker(progressGroupId, trackers);
      this._itemTrackersForProgressBarGroup.push(group);
    }
  }
}
