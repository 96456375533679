import { inject, Injectable } from '@angular/core';
import { Requester } from '@compass/http';
import { Logger } from '@compass/logging';
import { AuthenticationService } from '~/shared/services/security/authentication.service';

/**
 * DebugClient is a service responsible for handling debug-related API calls.
 * It provides methods to interact with the debugging backend, such as resetting debug states.
 * This service is configured to be provided at the root level.
 */
@Injectable({
  providedIn: 'root',
})
export class DebugClient {
  private readonly _requester = inject(Requester);
  private readonly _logger = inject(Logger);
  private readonly _auth = inject(AuthenticationService);

  async reset(): Promise<boolean> {
    const result = await this._requester
      .delete('debug/reset')
      .addRouteSegments(this._auth.startCode)
      .send();

    if (!result.isSuccess) {
      this._logger.error('Failed to reset assessment revision.', result.errors);
    }

    return result.isSuccess;
  }
}
