import { ItemTracker } from '~/shared/models/assessment/item-tracker';

/**
 * A class to track group-level statistics and operations related to a collection of PresentationItemTracker objects.
 *
 * This class aggregates visibility and participation metrics of multiple
 * PresentationItemTracker objects, providing a consolidated view for the group.
 */
export class GroupItemTracker {
  readonly items: readonly ItemTracker[];

  /**
   * Calculates the total seconds visible count by summing up the `secondsVisibleCount` property of each item.
   *
   * @return {number} The total seconds visible count of all items.
   */
  get secondsVisibleCount(): number {
    return this.items.reduce((acc, curr) => acc + curr.secondsVisibleCount, 0);
  }

  /**
   * Retrieves the total count of times items have been shown to participants.
   *
   * @return {number} The cumulative count of the shownToParticipantCount property from all items.
   */
  get shownToParticipantCount(): number {
    return this.items.reduce(
      (acc, curr) => acc + curr.shownToParticipantCount,
      0,
    );
  }

  /**
   * Gets the total count of items.
   * @return {number} The total number of items.
   */
  get totalCount(): number {
    return this.items.length;
  }

  constructor(
    public readonly groupId: string,
    presentationItemTrackers: ItemTracker[],
  ) {
    this.items = Object.freeze(presentationItemTrackers);
  }

  /**
   * Retrieves the presentation item tracker associated with the given question ID.
   *
   * @param {string} questionId - The unique identifier for the question to find its associated tracker.
   * @return {ItemTracker | undefined} The tracker corresponding to the specified question ID, or undefined if no match is found.
   */
  getItemTracker(questionId: string): ItemTracker | undefined {
    return this.items.find(item => item.questionId === questionId);
  }

  public static empty(): GroupItemTracker {
    return new GroupItemTracker('', []);
  }
}
