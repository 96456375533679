import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PresentationService } from '../../../services/assessment/presentation.service';
import { AssessmentContentService } from '../../../services/assessment/assessment-content.service';
import { FormsModule } from '@angular/forms';
import {
  NavigationResult,
  NavigationService,
} from '~/shared/services/assessment/navigation.service';

@Component({
  selector: 'cp-par-debug-navigation',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './debug-navigation.component.html',
})
export class DebugNavigationComponent {
  protected readonly NavigationResult = NavigationResult;

  constructor(
    protected readonly presentation: PresentationService,
    protected readonly content: AssessmentContentService,
    protected readonly navigation: NavigationService,
  ) {}

  protected goToGroup(groupSourceId: string): void {
    const groupIndex = this.presentation.getIndexForGroup(groupSourceId);

    this.moveToIndex(groupIndex);
  }

  protected moveToIndex(index?: number): void {
    if (index === undefined) {
      return;
    }

    this.presentation.moveToIndex(index);
  }
}
