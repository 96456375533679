import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeyboardKey } from '../../services/ui/keyboard.service';
import { HotkeyDirective } from '../../directives/hotkey.directive';
import {
  AssessmentState,
  AssessmentStateService,
} from '../../services/assessment/assessment-state.service';
import { AssessmentContentService } from '../../services/assessment/assessment-content.service';

@Component({
  selector: 'cp-par-resume-button',
  standalone: true,
  imports: [CommonModule, HotkeyDirective],
  templateUrl: './resume-button.component.html',
})
export class ResumeButtonComponent {
  private readonly _state = inject(AssessmentStateService);

  protected readonly content = inject(AssessmentContentService);
  protected readonly KeyboardKey = KeyboardKey;

  protected onClick(): void {
    this._state.setState(AssessmentState.InProgress);
  }
}
