import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviousButtonComponent } from '../previous-button/previous-button.component';
import { CalculatorButtonComponent } from '../calculator-button/calculator-button.component';
import { ForwardButtonComponent } from '../forward-button/forward-button.component';

@Component({
  selector: 'cp-par-mobile-footer',
  standalone: true,
  imports: [
    CommonModule,
    PreviousButtonComponent,
    CalculatorButtonComponent,
    ForwardButtonComponent,
  ],
  templateUrl: './mobile-footer.component.html',
})
export class MobileFooterComponent {}
