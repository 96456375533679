<div class="container-fluid">
  <div class="row">
    <button
      class="col btn btn-primary"
      [disabled]="!presentation.current.previous.presentationItem"
      (click)="
        moveToIndex(presentation.current.previous.presentationItem?.index)
      ">
      Previous item
    </button>
    <button
      class="col btn btn-primary ms-2"
      [disabled]="!presentation.current.next.presentationItem"
      (click)="moveToIndex(presentation.current.next.presentationItem?.index)">
      Next item
    </button>

    <button
      class="col btn btn-primary ms-2"
      [disabled]="!presentation.current.previous.contentGroup"
      (click)="moveToIndex(presentation.current.previous.contentGroup?.index)">
      Previous group
    </button>
    <button
      class="col btn btn-primary ms-2"
      [disabled]="!presentation.current.next.contentGroup"
      (click)="moveToIndex(presentation.current.next.contentGroup?.index)">
      Next group
    </button>
  </div>

  <div class="row mt-2">
    <select class="col form-select" #select>
      <option
        *ngFor="let group of content.contentGroups"
        [value]="group.sourceId">
        {{ group.label || 'N/A' }} ({{ group.sourceId }})
      </option>
    </select>

    <button
      class="col-auto btn btn-primary ms-2"
      (click)="goToGroup(select.value)">
      Go
    </button>
  </div>

  <div class="row mt-1">
    <div class="col">
      <small>
        <b>Note:</b> Navigating to a restricted item/group could result in
        incorrect rendering or server's refusal to accept and/or provide data.
      </small>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col px-0">
      <table class="table table-sm table-striped">
        <tbody>
          <tr>
            <td>Previous navigation status</td>
            <td>
              {{ NavigationResult[navigation.canGoBack()] }}
            </td>
          </tr>
          <tr>
            <td>Next navigation status</td>
            <td>
              {{ NavigationResult[navigation.canGoNext()] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
