import { Injectable, signal, WritableSignal } from '@angular/core';
import { ContentGroup } from '../../models/assessment/content-group';
import { PresentationItem } from '../../models/assessment/presentation-item';
import { Logger } from '@compass/logging';
import { ReplaySubject } from 'rxjs';
import { ContentClient } from '../../clients/content.client';

export type IndexedItem = {
  index: number;
};

export type ContentGroupWithIndex = IndexedItem & ContentGroup;
export type PresentationItemWithIndex = IndexedItem & PresentationItem;

export type ContentGroupChangeArgs = {
  oldGroup?: ContentGroup;
  newGroup: ContentGroup;
};

export type PresentationItemChangeArgs = {
  oldItem?: PresentationItemWithIndex;
  newItem: PresentationItemWithIndex;
};

export type PresentationInfo = {
  isFirstInAssessment: boolean;
  isFirstInGroup: boolean;
  isLastInAssessment: boolean;
  isLastInGroup: boolean;
  presentationItem: PresentationItemWithIndex;
  contentGroup: ContentGroupWithIndex;
};

export type CurrentPresentationInfo = PresentationInfo & {
  previous: {
    presentationItem?: PresentationItemWithIndex;
    contentGroup?: ContentGroupWithIndex;
  };
  next: {
    presentationItem?: PresentationItemWithIndex;
    contentGroup?: ContentGroupWithIndex;
  };
};

@Injectable({
  providedIn: 'root',
})
export class PresentationService {
  private readonly _contentGroupChanged =
    new ReplaySubject<ContentGroupChangeArgs>(1);
  private readonly _presentationItemChanged =
    new ReplaySubject<PresentationItemChangeArgs>(1);
  private readonly _presentationItems: CurrentPresentationInfo[] = [];

  private _currentPresentationIndex: number = 0;

  readonly contentGroupChange$ = this._contentGroupChanged.asObservable();
  readonly presentationItemChange$ =
    this._presentationItemChanged.asObservable();

  get presentationItems(): Readonly<CurrentPresentationInfo>[] {
    return this._presentationItems;
  }

  get current(): CurrentPresentationInfo {
    return this._presentationItems[this._currentPresentationIndex];
  }

  public hideChoices: WritableSignal<boolean> = signal(false);

  constructor(
    contentClient: ContentClient,
    private readonly _logger: Logger,
  ) {
    this._presentationItems = this.flattenContentItems(
      contentClient.data.content,
    );

    this._currentPresentationIndex = this.getStartIndex(
      contentClient.data.progress?.presentationItemPointer,
    );

    this.contentItemChange(this._currentPresentationIndex);
  }

  moveToIndex(index: number): void {
    if (index < 0 || index > this._presentationItems.length - 1) return;
    if (index === this._currentPresentationIndex) return;

    const oldIndex = this._currentPresentationIndex;

    this._currentPresentationIndex = index;

    this.contentItemChange(this._currentPresentationIndex, oldIndex);
  }

  /**
   * Retrieves the index of the content group associated with the specified group source ID.
   *
   * @param {string} groupSourceId - The identifier of the group source to search for.
   * @return {number} The index of the content group if found; otherwise, -1.
   */
  getIndexForGroup(groupSourceId: string): number {
    return this._presentationItems.findIndex(
      i => i.contentGroup.sourceId === groupSourceId,
    );
  }

  /**
   * Retrieves the content group associated with the specified group source ID.
   *
   * @param {string} groupSourceId - The source ID of the content group to retrieve.
   * @return {Readonly<ContentGroupWithIndex & {
   *           previous?: ContentGroupWithIndex;
   *           next?: ContentGroupWithIndex;
   *         }>} The content group matching the specified source ID, or undefined if no matching group is found.
   */
  getContentGroup(groupSourceId: string):
    | Readonly<
        ContentGroupWithIndex & {
          previous?: ContentGroupWithIndex;
          next?: ContentGroupWithIndex;
        }
      >
    | undefined {
    const groupItem = this._presentationItems.find(
      i => i.contentGroup.sourceId === groupSourceId,
    );

    if (!groupItem) {
      return undefined;
    }

    return {
      ...groupItem.contentGroup,
      previous: groupItem.previous.contentGroup,
      next: groupItem.next.contentGroup,
    };
  }

  private getStartIndex(itemPointer?: string): number {
    if (!itemPointer) return 0;

    const itemIndex = this._presentationItems.findIndex(
      i => i.presentationItem.questionId === itemPointer,
    );

    return itemIndex >= 0 ? itemIndex : 0;
  }

  private contentItemChange(newIndex: number, oldIndex?: number): void {
    if (oldIndex === newIndex) return;

    const oldItem =
      oldIndex === undefined ? undefined : this._presentationItems[oldIndex];
    const newItem = this._presentationItems[newIndex];

    // Emit content group change
    if (
      !oldItem ||
      oldItem.contentGroup.sourceId !== newItem.contentGroup.sourceId
    ) {
      this._contentGroupChanged.next({
        newGroup: newItem.contentGroup,
        oldGroup: oldItem?.contentGroup,
      });
      this.logContentGroupChanged(newItem.contentGroup);
    }

    // Emit presentation item change
    this._presentationItemChanged.next({
      oldItem: oldItem?.presentationItem,
      newItem: newItem.presentationItem,
    });
    this.logItemChanged();
  }

  private flattenContentItems(
    contentGroups: ContentGroup[],
  ): CurrentPresentationInfo[] {
    const items: CurrentPresentationInfo[] = [];
    let globalIndex = 0;

    // Filter out empty groups
    contentGroups = contentGroups.filter(cg => cg.presentationItems.length > 0);

    for (let i = 0; i < contentGroups.length; i++) {
      const currentContentGroup = contentGroups[i];
      const nextContentGroup =
        i < contentGroups.length - 1 ? contentGroups[i + 1] : undefined;
      const previousContentGroup = i > 0 ? contentGroups[i - 1] : undefined;

      for (let j = 0; j < currentContentGroup.presentationItems.length; j++) {
        const currentPresentationItem =
          currentContentGroup.presentationItems[j];
        // Next item in the group OR first item in next group (if exists)
        const nextPresentationItem =
          j < currentContentGroup.presentationItems.length - 1
            ? currentContentGroup.presentationItems[j + 1]
            : nextContentGroup?.presentationItems[0];
        // Previous item in the group OR last item in previous group (if exists)
        const previousPresentationItem =
          j > 0
            ? currentContentGroup.presentationItems[j - 1]
            : previousContentGroup?.presentationItems.slice(-1)[0];

        items.push({
          contentGroup: {
            ...currentContentGroup,
            index: globalIndex - j,
          },
          presentationItem: {
            ...currentPresentationItem,
            index: globalIndex,
          },
          isFirstInAssessment: i === 0 && j === 0,
          isFirstInGroup: j === 0,
          isLastInAssessment:
            i === contentGroups.length - 1 &&
            j === currentContentGroup.presentationItems.length - 1,
          isLastInGroup: j === currentContentGroup.presentationItems.length - 1,
          next: {
            contentGroup: PresentationService.getWithIndexOrUndefined(
              nextContentGroup,
              globalIndex + currentContentGroup.presentationItems.length - j,
            ),
            presentationItem: PresentationService.getWithIndexOrUndefined(
              nextPresentationItem,
              globalIndex + 1,
            ),
          },
          previous: {
            contentGroup: PresentationService.getWithIndexOrUndefined(
              previousContentGroup,
              globalIndex -
                (previousContentGroup?.presentationItems?.length ?? 0) -
                j,
            ),
            presentationItem: PresentationService.getWithIndexOrUndefined(
              previousPresentationItem,
              globalIndex - 1,
            ),
          },
        });

        // Increment the global index
        globalIndex++;
      }
    }

    return items;
  }

  private static getWithIndexOrUndefined<T>(
    value: T | undefined,
    index: number,
  ): (IndexedItem & T) | undefined {
    if (!value) {
      return undefined;
    }

    return {
      ...value,
      index,
    };
  }

  private logItemChanged(): void {
    this._logger.debug(
      'Presentation item was loaded.',
      this.current.presentationItem,
    );
  }

  private logContentGroupChanged(contentGroup: ContentGroup): void {
    this._logger.debug('Content group was loaded.', contentGroup);
  }
}
