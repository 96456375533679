<p-dialog
  #dialogInstance
  [(visible)]="visible"
  [modal]="true"
  [closable]="
    options.showCloseButton ||
    options.closeOnBackdropClick ||
    options.closeOnEscape
  "
  [closeOnEscape]="options.closeOnEscape"
  [dismissableMask]="options.closeOnBackdropClick"
  [maximizable]="options.fullscreen"
  [maximizeButtonProps]="{ style: { display: 'none' } }"
  [draggable]="false"
  (onHide)="cancel()"
  [showHeader]="showHeader"
  styleClass="w-md-30">
  @if (showHeader) {
    <ng-template #header>
      <cp-modal-header
        [title]="displayOptions.title!"
        [titleContext]="displayOptions.titleContext"
        [dialogStyle]="dialogStyle" />
    </ng-template>
  }
  <div
    class="pt-3 h-100"
    [ngClass]="{
      'border-top': showHeader,
      'border-bottom pb-3': showFooter,
    }">
    <ng-container
      *ngIf="displayOptions.type === 'content' && !options.allowHtml">
      {{ displayOptions.content }}
    </ng-container>
    <ng-container
      *ngIf="displayOptions.type === 'content' && options.allowHtml">
      <div [innerHTML]="displayOptions.content | safeHtml"></div>
    </ng-container>
    <ng-container *ngIf="displayOptions.type === 'component'">
      <ng-container
        *ngComponentOutlet="
          displayOptions.component;
          inputs: displayOptions.context ?? {}
        " />
    </ng-container>
  </div>
  @if (showFooter) {
    <ng-template #footer>
      <button
        class="btn btn-link me-2"
        *ngIf="options.showCancel"
        (click)="cancel()">
        {{ options.cancelText }}
      </button>
      <button
        class="btn btn-primary"
        *ngIf="options.showConfirm"
        (click)="confirm()"
        [disabled]="
          confirmPending ||
          (options.confirmDisabled !== undefined && options.confirmDisabled())
        ">
        <div
          class="spinner-border spinner-border-sm"
          *ngIf="confirmPending"></div>
        <ng-container *ngIf="!confirmPending">
          {{ options.confirmText }}
        </ng-container>
      </button>
    </ng-template>
  }
</p-dialog>
