import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeyboardShortcutsHelpComponent } from '../keyboard-shortcuts-help/keyboard-shortcuts-help.component';
import { DeviceInfoService } from '@compass/environment';
import { PreviousButtonComponent } from '../previous-button/previous-button.component';
import { CalculatorButtonComponent } from '../calculator-button/calculator-button.component';
import { HotkeyDirective } from '../../directives/hotkey.directive';
import { KeyboardKey } from '../../services/ui/keyboard.service';
import { Drawer } from 'primeng/drawer';
import { ForwardButtonComponent } from '../forward-button/forward-button.component';

@Component({
  selector: 'cp-par-desktop-footer',
  standalone: true,
  imports: [
    CommonModule,
    KeyboardShortcutsHelpComponent,
    PreviousButtonComponent,
    CalculatorButtonComponent,
    HotkeyDirective,
    Drawer,
    ForwardButtonComponent,
  ],
  templateUrl: './desktop-footer.component.html',
  styleUrls: ['./desktop-footer.component.scss'],
})
export class DesktopFooterComponent {
  protected readonly KeyboardKey = KeyboardKey;
  protected showDrawer: boolean = false;

  constructor(protected readonly deviceInfo: DeviceInfoService) {}

  protected toggleDrawer(): void {
    this.showDrawer = !this.showDrawer;
  }
}
