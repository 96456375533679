import { Injectable, TemplateRef } from '@angular/core';
import { ComponentMakerService } from '@compass/ui';
import { DialogOutletComponent } from '../components/dialog-outlet/dialog-outlet.component';
import { DialogOptions } from '../types/dialog-options';
import {
  CancelButtonDialogConfig,
  ConfirmButtonDialogConfig,
  defaultDialogConfiguration,
  DialogCloseConfig,
  DialogConfig,
  DialogDisplayConfig,
  DialogTemplateContextConfig,
} from '../types/dialog-config';
import { TemplateModalComponent } from '../components/template-modal/template-modal.component';
import { ModalReference } from '../models/modal-reference';
import { DialogStyle } from '../types/dialog-style';
import {
  ContentWithComponent,
  ContentWithTemplate,
} from '../types/content-type';

@Injectable({ providedIn: 'root' })
export class ModalService {
  constructor(private readonly _componentMaker: ComponentMakerService) {}

  /**
   * Displays an info modal dialog with the given content, title, and configuration.
   *
   * @param {ContentWithComponent<TContent>} content - The content of the dialog.
   * @param {ContentWithTemplate<TTitle>} [title] - The title of the dialog.
   * @param {Partial<
   *       DialogConfig & DialogTemplateContextConfig<TContent, TTitle>
   *     >} [config] - The configuration options for the dialog.
   *
   * @return {ModalReference} - A reference to the displayed modal dialog.
   */
  info<TTitle = unknown, TContent = unknown>(
    content: ContentWithComponent<TContent>,
    title?: ContentWithTemplate<TTitle>,
    config?: Partial<
      DialogConfig & DialogTemplateContextConfig<TContent, TTitle>
    >,
  ): ModalReference {
    return this.show(content, title, {
      showCancel: false,
      showCloseButton: false,
      style: 'info',
      ...config,
    });
  }

  /**
   * Displays an error modal dialog with the given content, title, and configuration.
   *
   * @param {ContentWithComponent<TContent>} content - The content of the dialog.
   * @param {ContentWithTemplate<TTitle>} [title] - The title of the dialog.
   * @param {Partial<
   *       DialogConfig & DialogTemplateContextConfig<TContent, TTitle>
   *     >} [config] - The configuration options for the dialog.
   *
   * @return {ModalReference} - A reference to the displayed modal dialog.
   */
  error<TTitle = unknown, TContent = unknown>(
    content: ContentWithComponent<TContent>,
    title?: ContentWithTemplate<TTitle>,
    config?: Partial<
      DialogConfig & DialogTemplateContextConfig<TContent, TTitle>
    >,
  ): ModalReference {
    return this.show(content, title, {
      showCancel: false,
      closeOnEscape: false,
      closeOnBackdropClick: false,
      showCloseButton: false,
      style: 'danger',
      ...config,
    });
  }

  /**
   * Displays a success modal dialog with the given content, title, and configuration.
   *
   * @param {ContentWithComponent<TContent>} content - The content of the dialog.
   * @param {ContentWithTemplate<TTitle>} [title] - The title of the dialog.
   * @param {Partial<
   *       DialogCloseConfig &
   *         ConfirmButtonDialogConfig &
   *         DialogTemplateContextConfig<TContent, TTitle>
   *     >} [config] - The configuration options for the dialog.
   *
   * @return {ModalReference} - A reference to the displayed modal dialog.
   */
  success<TTitle = unknown, TContent = unknown>(
    content: ContentWithComponent<TContent>,
    title?: ContentWithTemplate<TTitle>,
    config?: Partial<
      DialogCloseConfig &
        ConfirmButtonDialogConfig &
        DialogTemplateContextConfig<TContent, TTitle>
    >,
  ): ModalReference {
    return this.show(content, title, {
      showCancel: false,
      closeOnEscape: false,
      closeOnBackdropClick: false,
      showCloseButton: false,
      style: 'success',
      ...config,
    });
  }

  /**
   * Displays an alert modal with the given content and title.
   *
   * @param {ContentWithComponent<TContent>} content - The content of the modal.
   * @param {ContentWithTemplate<TTitle>} [title] - The title of the modal.
   * @param {Partial<
   *       DialogTemplateContextConfig<TContent, TTitle> &
   *         DialogCloseConfig &
   *         DialogDisplayConfig
   *     >} [config] - Additional configuration for the modal.
   * @returns {ModalReference} - The reference to the modal.
   */
  alert<TTitle = unknown, TContent = unknown>(
    content: ContentWithComponent<TContent>,
    title?: ContentWithTemplate<TTitle>,
    config?: Partial<
      DialogTemplateContextConfig<TContent, TTitle> &
        DialogCloseConfig &
        DialogDisplayConfig
    >,
  ): ModalReference {
    return this.show(content, title, {
      showCancel: false,
      showConfirm: false,
      closeOnEscape: false,
      closeOnBackdropClick: false,
      showCloseButton: false,
      ...config,
    });
  }

  /**
   * Opens a confirmation dialog with specified content, title, and configuration options.
   * Provides a promise that resolves to a boolean indicating whether the user confirmed or canceled the action.
   *
   * @param {ContentWithComponent<TContent>} content - The content to display in the confirmation dialog. This can be a string, HTML, or a component.
   * @param {ContentWithTemplate<TTitle>} [title] - The title for the confirmation dialog. Defaults to 'Confirm your selection' if not provided.
   * @param {Partial<ConfirmButtonDialogConfig & CancelButtonDialogConfig & DialogDisplayConfig> & { style?: DialogStyle }} [config] - Additional configuration options for the dialog, such as button styling, behavior options, or dialog style.
   * @return {Promise<boolean>} A promise that resolves to a boolean value indicating the user's confirmation (true for confirm, false for cancel).
   */
  confirm<TContent = unknown, TTitle = unknown>(
    content: ContentWithComponent<TContent>,
    title?: ContentWithTemplate<TTitle>,
    config?: Partial<
      ConfirmButtonDialogConfig & CancelButtonDialogConfig & DialogDisplayConfig
    > & {
      style?: DialogStyle;
    },
  ): Promise<boolean> {
    return this.show(content, title ?? 'Confirm your selection', {
      closeOnBackdropClick: false,
      closeOnEscape: false,
      showCloseButton: false,
      style: 'info',
      ...config,
    }).result;
  }

  /**
   * Displays a modal dialog with the given content, title, and configuration.
   *
   * @param {ContentWithComponent<TContent>} content - The content of the dialog, wrapped in a component.
   * @param {ContentWithTemplate<TTitle>} [title] - The title of the dialog, wrapped in a template.
   * @param {Partial<DialogConfig> & DialogTemplateContextConfig<TContent, TTitle> & { style?: DialogStyle }} [config] - Optional configuration for the dialog, including style.
   * @returns {ModalReference} - The reference to the created dialog modal.
   */
  show<TContent = unknown, TTitle = unknown>(
    content: ContentWithComponent<TContent>,
    title?: ContentWithTemplate<TTitle>,
    config?: Partial<DialogConfig> &
      Partial<DialogTemplateContextConfig<TContent, TTitle>> & {
        style?: DialogStyle;
      },
  ): ModalReference {
    const dialogRef = this._componentMaker.createInDom(DialogOutletComponent, {
      displayOptions: this.getDialogDisplayOptions(
        content,
        title,
        config?.contentContext,
        config?.titleContext,
      ),
      options: { ...defaultDialogConfiguration, ...config },
      dialogStyle: config?.style,
    });

    dialogRef.setInput('instance', dialogRef);

    return ModalReference.from(dialogRef);
  }

  private getDialogDisplayOptions(
    content: ContentWithComponent,
    title?: ContentWithTemplate,
    contentContext?: Record<string, unknown>,
    titleContext?: Record<string, unknown>,
  ): DialogOptions {
    if (typeof content === 'string') {
      return {
        title,
        titleContext,
        type: 'content',
        content,
      };
    } else if (content instanceof TemplateRef) {
      return {
        title,
        titleContext,
        type: 'component',
        component: TemplateModalComponent,
        context: {
          template: content,
          templateContext: contentContext,
        },
      };
    } else {
      return {
        title,
        titleContext,
        type: 'component',
        component: content,
        context: contentContext,
      };
    }
  }
}
