<div class="row align-items-center">
  <div class="col">
    <cp-par-previous-button />
  </div>

  <div class="col d-flex justify-content-center">
    <cp-par-calculator-button />
  </div>

  <div class="col text-end">
    <cp-par-forward-button />
  </div>
</div>
