import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressStoreService } from '../../../services/security/progress-store.service';
import { SessionService } from '../../../services/security/session.service';
import { DebugService } from '~/shared/services/environment/debug.service';

@Component({
  selector: 'cp-par-debug-actions',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './debug-actions.component.html',
  styleUrls: ['./debug-actions.component.scss'],
})
export class DebugActionsComponent {
  constructor(
    protected readonly debug: DebugService,
    protected readonly progressStore: ProgressStoreService,
    protected readonly session: SessionService,
  ) {}

  protected toggleProgressPersistence(): void {
    if (this.progressStore.isTrackingEnabled) {
      this.progressStore.stopProgressTracking();
    } else {
      this.progressStore.startProgressTracking();
    }

    this.debug.setOption(
      'progressPersistence',
      this.progressStore.isTrackingEnabled,
    );
  }

  protected toggleSessionTracking(): void {
    this.session.enabled = !this.session.enabled;

    this.debug.setOption('sessionTracking', this.session.enabled);
  }
}
