import { HttpRequestMethod } from './http-request-method';
import { HttpResponseType } from './http-response-type';

export type SupportedHttpQueryType = string | number | boolean;

export const DefaultHttpRequestOptions: HttpRequestOptions = {
  method: HttpRequestMethod.Get,
  responseType: HttpResponseType.Json,
};

export interface HttpRequestOptions {
  method: HttpRequestMethod;
  responseType: HttpResponseType;
  body?: unknown;
  params?: Record<string, SupportedHttpQueryType>;
}
