<button
  class="btn btn-primary d-flex justify-content-between align-items-center py-3 px-4 gap-2"
  *ngIf="calculator.enabled"
  ngbTooltip="Open Calculator / Close Calculator"
  (click)="calculator.toggle()"
  cpDoNotFocus>
  <cp-fa-icon iconStyle="light">calculator</cp-fa-icon>
  <ng-container *ngIf="deviceInfo.device.isDesktop && window.innerWidth > 576">
    <p class="my-auto"><u>C</u>alculator</p>
  </ng-container>
</button>
